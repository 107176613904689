var t = require('transducers-js')

import { partitionAll } from './partitionall'
import { nil } from './utils2'

// Downsample with lookahead 2
var Downsample = function (filter_out_fn) {
  return function (xf) {
    var last = null
    var next = null
    var filtered_out = 0
    return {
      '@@transducer/init': function () {
        return xf['@@transducer/init']()
      },
      '@@transducer/result': function (result) {
        var intermediate = result
        if (!nil(last)) {
          intermediate = xf['@@transducer/step'](result, last)
        }
        if (t.isReduced(intermediate)) {
          return xf['@@transducer/result'](t.unreduced(intermediate))
        }
        if (!nil(next)) {
          intermediate = xf['@@transducer/step'](intermediate, next)
        }
        if (t.isReduced(intermediate)) {
          return xf['@@transducer/result'](t.unreduced(intermediate))
        }
        return xf['@@transducer/result'](intermediate)
      },
      '@@transducer/step': function (result, input) {
        if (last === null) {
          last = input
          return result
        }
        if (next === null) {
          next = input
          return result
        }
        if (filter_out_fn(last, next, input)) {
          next = input
          filtered_out += 1
          return result
        } else {
          let temp = last
          last = next
          next = input
          return xf['@@transducer/step'](result, temp)
        }
      },
    }
  }
}

export const downsample = Downsample
export const pairwise = t.comp(
  partitionAll(2, 1),
  t.filter((x) => x.length == 2)
)

function appender() {
  return {
    '@@transducer/init': function () {
      return []
    },
    '@@transducer/result': function (result) {
      return result
    },
    '@@transducer/step': function (result, input) {
      result.push(input)
      return result
    },
  }
}

export function max(start = 0) {
  return {
    '@@transducer/init': function () {
      return start
    },
    '@@transducer/result': function (result) {
      return result
    },
    '@@transducer/step': function (result, input) {
      if (input > result) {
        return input
      } else {
        return result
      }
    },
  }
}

export function enumerate(start = 0) {
  var counter = start
  return function (xf) {
    return {
      '@@transducer/init': function () {
        return xf['@@transducer/init']()
      },
      '@@transducer/result': function (result) {
        return xf['@@transducer/result'](result)
      },
      '@@transducer/step': function (result, input) {
        let r = xf['@@transducer/step'](result, [counter, input])
        counter += 1
        return r
      },
    }
  }
}

export function insert_last(v) {
  var value = v
  return function (xf) {
    return {
      '@@transducer/init': function () {
        return xf['@@transducer/init']()
      },
      '@@transducer/result': function (result) {
        if (t.isReduced(result)) {
          return xf['@@transducer/result'](t.unreduced(result))
        } else {
          let next = xf['@@transducer/step'](result, value)
          return xf['@@transducer/result'](t.unreduced(next))
        }
      },
      '@@transducer/step': function (result, input) {
        return xf['@@transducer/step'](result, input)
      },
    }
  }
}

export function* eduction(xf, iter) {
  let txf = xf(appender())
  var reduced = false
  for (var item of iter) {
    var result = txf['@@transducer/step']([], item)
    if (t.isReduced(result)) {
      reduced = true
      result = t.unreduced(result)
    }
    yield* result
    result = []
    if (reduced) {
      break
    }
  }
  let pending = txf['@@transducer/result']([])
  yield* pending
}

export function zip(arrays) {
  return arrays[0].map(function (_, i) {
    return arrays.map(function (array) {
      return array[i]
    })
  })
}
