import { BackSide, MeshStandardMaterial, SphereGeometry, Vector3 } from 'three'
import { constants, editorTypes } from './constants'
import { Disk } from './disk'
import { CustomCatmullRom, Spline } from './spline-classes'

export class Sphere {
  u: number
  v: number
  radius: number
  occlusion = 0.5

  constructor(u: number, v: number, radius: number, occlusion = 0.5) {
    this.u = u
    this.v = v
    this.radius = radius
    this.occlusion = occlusion
  }

  getSplinePoint(spline: Spline | CustomCatmullRom) {
    if (spline instanceof Spline)
      return (spline as Spline).curve.getPhysicalPoint(
        1,
        (spline as Spline).modelType.editorType
      )
    else
      return (spline as CustomCatmullRom).getPhysicalPoint(
        1,
        editorTypes.bubble
      )
  }

  getViewerCenter(spline: Spline | CustomCatmullRom) {
    const splinePoint = this.getSplinePoint(spline)
    const point = new Vector3(0, 0, 0)
    point.y = splinePoint.y * this.v * -0.1
    point.z = splinePoint.x * 0.05 - (this.radius / 10) * this.occlusion

    point.applyAxisAngle(new Vector3(0, 1, 0), (1 - this.u) * 2 * Math.PI)
    return point
  }

  getPhysicalCenter(spline: Spline | CustomCatmullRom) {
    const splinePoint = this.getSplinePoint(spline)
    const point = new Vector3(0, 0, 0)
    point.z = splinePoint.y * this.v
    point.y = splinePoint.x * 0.5 - this.radius * this.occlusion

    point.applyAxisAngle(new Vector3(0, 0, 1), this.u * 2 * Math.PI)
    return point
  }

  getCirclularSection(spline: Spline | CustomCatmullRom, sectionV: number) {
    const spherePhysicalCenter = this.getPhysicalCenter(spline)

    const splinePoint = this.getSplinePoint(spline)
    const sectionHeight = splinePoint.y * sectionV

    if (
      sectionHeight <= spherePhysicalCenter.z + this.radius &&
      sectionHeight >= spherePhysicalCenter.z - this.radius
    ) {
      const circleCenter = new Vector3(
        spherePhysicalCenter.x,
        spherePhysicalCenter.y,
        sectionHeight
      )
      const distanceFromSphereCenter = spherePhysicalCenter.z - sectionHeight

      const circleRadius = Math.sqrt(
        Math.pow(this.radius, 2) - Math.pow(distanceFromSphereCenter, 2)
      )

      return { circleCenter, circleRadius }
    }
  }

  getViewerGeometry(spline: Spline | CustomCatmullRom) {
    const sphere = new SphereGeometry(this.radius + 2, 32, 32)
    const center = this.getPhysicalCenter(spline)

    sphere.translate(center.x, center.y, center.z)

    sphere.scale(0.1, 0.1, 0.1)
    sphere.rotateX(Math.PI / 2)

    return sphere
  }

  static getViewerMaterial(visibility = false) {
    const newMaterial = new MeshStandardMaterial({
      color: 'pink',
      emissive: 'yellow',
      emissiveIntensity: 1.0,
      metalness: 0.2,
      roughness: 0.5,
      transparent: true,
      visible: visibility,
      opacity: 0.6,
      wireframe: false,
    })

    return newMaterial
  }

  static serialize(obj: Sphere | Disk) {
    if (obj instanceof Disk) return Disk.serialize(obj)
    else return JSON.stringify([obj.u, obj.v, obj.radius, obj.occlusion])
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)
    return new Sphere(jsonObj[0], jsonObj[1], jsonObj[2], jsonObj[3])
  }
}
