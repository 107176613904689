import { assoc } from './immutant'
import { min, length, max, sum, first, last, nil } from './utils2'
import { enumerate } from './itertools'
import { assocPath } from 'lodash/fp'
import {get_bbox as _get_bbox} from './shared'

var t = require('transducers-js')

const min_d = 0.0000001 // values smaller than this are set to zero in gcode. gcode cannot handle exponential notation e.g. (2.5e-17).


function make_global(p, global_transform) {
  const [x, y, z] = p
  var gx = x + global_transform.dx
  var gx = Math.abs(gx) < min_d ? 0 : gx
  var gy = y + global_transform.dy
  var gy = Math.abs(gy) < min_d ? 0 : gy
  var gz = z + global_transform.dz
  var gz = Math.abs(gz) < min_d ? 0 : gz
  return [gx, gy, gz]
}

function check_point(p, design_space) {
  let [x, y, z] = p
  if (x > design_space.x_max) {
    throw 'exceeded xmax'
  }
  if (x < design_space.x_min) {
    throw 'exceeded xmin'
  }
  if (y > design_space.y_max) {
    throw 'exceeded ymax'
  }
  if (y < design_space.y_min) {
    throw 'exceeded ymin'
  }
  if (z > design_space.z_max) {
    throw 'exceeded zmax'
  }
  if (z < design_space.z_min) {
    throw 'exceeded zmin'
  }
  return p
}

function to_point(p, opts) {
  const [x, y, z] = check_point(
    make_global(p, opts.global_transform),
    opts.design_space
  )
  if (opts.printer.firmware.cead_mode){
    return `extruderspeed (0, 0, 1)
G0 X${x} Y${y} F6000
G0 Z${z} F3000
extruderspeed (${opts.nozzle_size}, ${opts.layer_height}, ${opts.material.number})
`
  }else {
    return `G91; relative positioning
G0 Z1.0 F1000 ; move z up little to prevent scratching of print
G90; absolute positioning
M82
G92 E0
G00 X${x} Y${y} F6000
G0 Z${z} F3000
`
  }
}

function precise(x) {
  return Number.parseFloat(x).toFixed(4)
}

export function mm_per_second_to_mm_per_minute(x) {
  return x * 60
}

function calc_extrusion_rate_and_print_speed(i_p, opts) {
  let [i, p] = i_p
  let accelerating_factor = min([i / opts.ramp_up, 1])
  let filament_r = opts.filament_diameter / 2
  let filament_cross_section = filament_r * filament_r * Math.PI
  let l = length(p.v)
  let v = Math.abs(p.volume)
  let fast_speed = opts.max_print_speed
  let fast_extrusion_rate = (fast_speed * v) / l
  let used_extrusion_rate = min([fast_extrusion_rate, opts.max_flow_rate])
  let used_print_speed = (accelerating_factor * (used_extrusion_rate * l)) / v
  let total_extrusion = p.total_print_volume / filament_cross_section
  return assoc(p, {
    e: total_extrusion,
    f: mm_per_second_to_mm_per_minute(used_print_speed),
  })
}

function transform_to_coord_sys(p, opts) {
  const [x, y, z] = make_global(p.point, opts.global_transform)
  return assoc(p, { x: x, y: y, z: z })
}

function check(p, opts) {
  check_point([p.x, p.y, p.z], opts.design_space)
  return p
}

function print(p) {
  return `G1 X${precise(p.x)} Y${precise(p.y)} Z${precise(p.z)} E${precise(
    p.e
  )} F${precise(p.f)}
`
}

function make_cead_print(opts){
  function cead_print(p) {
    let extrusion_formular = p.extrusion_factor_changed ? `extruderspeed (${opts.nozzle_size}, ${opts.layer_height * p.e_factor }, ${opts.material.number})\n` : ''
    let cmd = `G1 X${precise(p.x)} Y${precise(p.y)} Z${precise(p.z)} F${precise(p.f)}
`
    return extrusion_formular + cmd
  }
  return cead_print
}


function _calc_slow_down(number, group, total_seconds, last_layer, opts) {
  if (group === 'object') {
    if (number === last_layer) {
      return 1
    }
    return Math.min(1, total_seconds / opts.layer_time)
  }
  return 1
}

function _max_layer_time(points, opts, last_layer) {
  let total_seconds = sum(points.map((x) => x.distance / x.f)) * 60
  let f = first(points)
  let slow_down = _calc_slow_down(
    f.meta.number,
    f.group,
    total_seconds,
    last_layer,
    opts
  )
  return points.map((x) => assoc(x, { slow_down: slow_down }))
}

function max_layer_time(opts, last_layer) {
  return t.comp(
    t.partitionBy((x) => x.meta.number),
    t.map((x) => _max_layer_time(x, opts, last_layer)),
    t.cat,
    t.map((x) => assoc(x, { f: x.f * x.slow_down }))
  )
}


function _apply_z_offset(point, opts){
  const [x, y, z] = point
  let offset = nil(opts.z_offset) ? 0 : opts.z_offset
  return [x, y, z + offset]
}

function apply_z_offset(p, opts){
  return assoc(p, {point: _apply_z_offset(p.point, opts)})
}

function generate(p, opts) {
  let last_layer = last(p.points).meta.number
  let print_fn = opts.printer.firmware.cead_mode? make_cead_print(opts): print
  return (
  to_point(_apply_z_offset(p.start, opts), opts) +
    t.into(
      '',
      t.comp(
        enumerate(1),
        t.map((x) => calc_extrusion_rate_and_print_speed(x, opts)),
        t.map((x) => apply_z_offset(x, opts)),
        t.map((x) => transform_to_coord_sys(x, opts)),
        t.map((x) => check(x, opts)),
        max_layer_time(opts, last_layer),
        t.map(print_fn)
      ),
      p.points
    )
  )
}

function generate_gcode(path, opts) {
  return t.into(
    '',
    t.map((x) => generate(x, opts)),
    path
  )
}

function get_bbox(path) {
  return _get_bbox([path.brim, path.shell, path.skirt, path.socket])
}

function _global_transform(opts, bbox) {
  let design_space = opts.design_space
  let nozzle_padding = opts.nozzle_size / 2;
  design_space.z_min = 0
  design_space.z_max = design_space.z
  var global_transform = { dx: 0, dy: 0, dz: 0 }
  if (opts.no_transform){
    opts.global_transform = global_transform
    return opts
  }
  if (opts.radial_coord_sys) {
    design_space.x_min = design_space.x / 2 - design_space.x + nozzle_padding
    design_space.y_min = design_space.y / 2 - design_space.y + nozzle_padding
    design_space.x_max = design_space.x - design_space.x / 2 - nozzle_padding
    design_space.y_max = design_space.y - design_space.y / 2 - nozzle_padding
  } else {
    design_space.x_min = nozzle_padding
    design_space.y_min = nozzle_padding
    design_space.x_max = design_space.x - nozzle_padding
    design_space.y_max = design_space.y - nozzle_padding
    global_transform.dx = -bbox.x.min + nozzle_padding + 10
    global_transform.dy = -bbox.y.min + nozzle_padding + 10
  }
  opts.global_transform = global_transform
  return opts
}

export function generate_gcode_annotated(path, opts) {
  opts = _global_transform(opts, get_bbox(path))
  let base_opts = assocPath(['max_flow_rate'], opts.brim.max_flow_rate, opts)
  base_opts = assocPath(
    ['max_print_speed'],
    opts.brim.max_print_speed,
    base_opts
  )
  console.log("generate skirt")
  let skirt_code = generate_gcode(path.skirt, base_opts)
  console.log("generate brim")
  let brim_code = generate_gcode(path.brim, base_opts)
  console.log("generate socket")
  let socket_code = generate_gcode(path.socket, base_opts)
  console.log("generate shell")
  let shell_code = generate_gcode(path.shell, opts)


  return [
    {
      tag: 'warmup',
      code: opts.printer.firmware.start_code,
    },
    {
      tag: 'skirt',
      code: skirt_code,
    },
    {
      tag: 'brim',
      code: brim_code,
    },
    {
      tag: 'socket',
      code: socket_code,
    },
    {
      tag: 'fan_start',
      code: `M106 S255 ; start fan
`,
    },
    {
      tag: 'shell',
      code: shell_code,
    },
    {
      tag: 'shutdown',
      code: opts.printer.firmware.end_code,
    },
  ]
}
