import { generate_nearby_ts } from './geometry'
import { pairwise, downsample } from './itertools'
import {
  offset,
  offset_n,
  clip,
  nearest_i_and_d,
  intersect,
  contains,
} from './clipping'
import {
  attach_deltas,
  add_volume,
  total_volume,
  discard_on_distance,
  apply_modifier,
  without_modifier,
} from './shared'
import { check_pairwise, check_p_pn } from './checks'
import {
  find_u_for_z,
  nil,
  last,
  first,
  distance2d,
  linear,
  sub2d,
  length2d,
  normalize2d,
  add2d,
  dot2d,
  intersection_circle_line,
} from './utils2'
var t = require('transducers-js')
import { scanline, scanline90 } from './scanline'

export function has_socket(s, nozzle_size) {
  const MIN_DX = 2 * nozzle_size
  const t_z = find_u_for_z(s, 0.1)
  const [x0, y0] = s(0, 0)
  const [x1, y1] = s(0, t_z)
  if ((x1 - x0) ** 2 + (y1 - y0) ** 2 >= MIN_DX ** 2) {
    return true
  } else {
    return false
  }
}

export function gen_path(s, u, opts) {
  let c = (tp) => s(tp, u)
  return t.into(
    [],
    t.map((t) => {
      let unmodified = { point: s(t, u), t: t, u: u, meta: { number: 0 } }
      let modified = apply_modifier(unmodified, s, opts)
      let [x, y, z] = modified.point
      return [x, y, t]
    }),
    generate_nearby_ts(c, opts, false)
  )
}

function is_degenerated(path) {
  if (path.length === 0) {
    return true
  }
  let [x0, y0] = path[0]
  for (var i = 1; i < path.length; i++) {
    let [xi, yi] = path[i]
    let dx = x0 - xi
    let dy = y0 - yi
    let dt = Math.sqrt(dx * dx + dy * dy)
    if (dt > 1) {
      return false
    }
  }
  return true
}

function curve_point_to_point(xy, z, opts, layer, group) {
  let t = xy[2]
  let visit_fn = opts.visit_fn
  let e_fn = opts.e_fn
  let height = z
  let thickness = opts.brim.layer_height
  let e_factor = 1
  if (!nil(t) && !nil(visit_fn) && !nil(e_fn)) {
    let visitIndex = visit_fn(t)
    let base_height = height - thickness
    //e_factor = e_fn(t)
    height = base_height + thickness * (visitIndex)
  }
  return {
    point: [xy[0], xy[1], height],
    t: xy[2],
    layer_height: thickness * e_factor,
    group: group,
    e_factor: e_factor,
    meta: { number: layer },
  }
}

function downsample_fn(opts){
  if  (opts.equidistant === true){
    return t.map((x)=>x)
  } else{
    return     downsample(
      discard_on_distance(opts.path.min_ddistance, opts.path.max_ddistance)
    )
  }
}
function curve_to_points(curve, z, opts, layer, group) {
  const xf = t.comp(
    t.map((xy) => curve_point_to_point(xy, z, opts, layer, group)),
    downsample_fn(opts),
    pairwise,
    attach_deltas,
    t.map(add_volume(opts.nozzle_size, opts.extrusion_multiplier)),
    t.map(total_volume(0)),
    check_pairwise(check_p_pn(opts, false))
  )
  let points = t.into([], xf, curve)
  return {
    start: [curve[0][0], curve[0][1], z],
    points: points,
  }
}

export function make_annotated(curves, height, thickness, opts, group) {
  var result = []
  for (var i = height, layer = 0; i <= thickness; i += height, layer += 1) {
    let paths = t.into(
      [],
      t.map((c) => curve_to_points(c, i, opts, layer, group)),
      curves
    )
    paths.forEach((p) => result.push(p))
  }
  return {
    brim: result,
    end_height: thickness,
  }
}

export function make_annotated2(curves, height, thickness, opts, group) {
  let paths = t.into(
    [],
    t.map((c) => curve_to_points(c, height, opts, height, group)),
    curves
  )
  return {
    brim: paths,
    end_height: thickness,
  }
}

function close_curve(curve) {
  let p0 = first(curve)
  curve.push(p0)
  return curve
}

function maybe_close(curve, path, opts) {
  let p0 = first(curve)
  let pn = last(curve)
  let d = distance2d(p0, pn)
  if (d < opts.nozzle_size) {
    curve.push(p0)
    return curve
  }
  // add an extra point with little offset and perpendicular to p0 pn,
  // otherwise clipper fails. This is
  // a bug in clipper
  let [xn, yn] = pn
  let v = sub2d(pn, p0)
  let [dx, dy] = p0
  let lv = length2d(v)

  let pe = [xn - dy / lv, yn + dx / lv]
  let new_curve = [p0, pn, pe]
  let clipped = clip(new_curve, path)
  if (!(clipped.length === 1)) {
    return curve
  }
  if (!(clipped[0].length === 2)) {
    return curve
  }
  let [pnn, p0n] = clipped[0]
  if (distance2d(pnn, pn) > 0.1 && distance2d(pnn, p0) > 0.1) {
    return curve
  }
  if (distance2d(p0n, pn) > 0.1 && distance2d(p0n, p0) > 0.1) {
    return curve
  }
  curve.push(p0)
  return curve
}

function is_close(p1, p2, opts) {
  if (distance2d(p1, p2) < opts.nozzle_size * 1.1) {
    return true
  } else {
    return false
  }
}

function trim_index(curve, p, opts) {
  var i = curve.length
  let pstart = curve[i - 1]
  let dstart = distance2d(curve[i - 1], p)
  if (dstart >= opts.nozzle_size) {
    let dir = normalize2d(sub2d(pstart, p))
    let [p_intersect, _] = intersection_circle_line(p, opts.nozzle_size, [
      pstart,
      dir,
    ])
    return [p_intersect, i]
  }
  for (; i > 1; i--) {
    let p1 = curve[i - 1]
    let p2 = curve[i - 2]
    let d2 = distance2d(p2, p)
    if (d2 == opts.nozzle_size) {
      return [p2, i - 2]
    }
    if (d2 < opts.nozzle_size) {
      continue
    }
    let dir = normalize2d(sub2d(p1, p2))
    let [p_intersect, _] = intersection_circle_line(p, opts.nozzle_size, [
      p2,
      dir,
    ])
    return [p_intersect, i - 1]
  }
  return [1, i]
}

function skeleton_path(curve, point, point2, opts) {
  let dir = normalize2d(sub2d(point2, point))
  var points = []
  let step = opts.nozzle_size / 20
  for (var i = step; i < opts.nozzle_size; i += step) {
    let inner = offset_n([curve], -1 * i, 1)
    if (inner.length > 2) {
      return points
    }
    let inner_curve = inner[1]
    let [index, d] = nearest_i_and_d(inner_curve, point)
    let nearest_point = inner_curve[index]
    let dot = dot2d(normalize2d(sub2d(nearest_point, point2)), dir)
    if (dot > 0) {
      points.push(inner_curve[index])
    }
  }
  return points
}

function connect_curves(curves, opts) {
  let first_curve = first(curves)
  var last_point = last(first_curve)
  let results = [first_curve]
  for (var i = 1; i < curves.length; i++) {
    let current_curve = last(results)
    let next_curve = curves[i]
    let next_first = first(next_curve)
    let last_curve = curves[i - 1]
    if (contains(last_curve, next_curve)) {
      let [point, index] = trim_index(current_curve, last_point, opts)
      current_curve = current_curve.slice(0, index)
      current_curve.push(point)
      if (distance2d(last_point, next_first) >= opts.nozzle_size * 2) {
        let skeleton_curve = skeleton_path(last_curve, last_point, point, opts)
        current_curve = current_curve.concat(skeleton_curve)
      }
      current_curve = current_curve.concat(next_curve)
      results[results.length - 1] = current_curve
    } else {
      results.push(next_curve)
    }
    last_point = last(next_curve)
  }
  return results
}

function gen_offset_curves(shape_path, inner_path, s, opts) {
  let raw_curves = offset([shape_path], -opts.layer_width)

  var curves = []
  if (has_socket(s, opts.nozzle_size) && !is_degenerated(inner_path)) {
    for (var i = 0; i < raw_curves.length; i++) {
      let clipped = clip([raw_curves[i]], [inner_path])
      curves = curves.concat(
        clipped.map((curve) => maybe_close(curve, [inner_path], opts))
      )
    }
    //todo decide if needed
    //curves.push(inner_path);
  } else {
    curves = raw_curves.map((curve) => close_curve(curve))
  }
  return connect_curves(curves, opts)
}

function gen_scanline_curves(shape_path, inner_path, s, opts) {
  var scanfn = null
  var curves = []
  if (opts.brim.fill_type == 'scanline') {
    scanfn = scanline
  } else {
    scanfn = scanline90
  }
  let shape1 = offset_n([shape_path], -opts.layer_width / 2, 1).slice(1)
  curves.push(shape_path)
  var shape = shape1
  if (has_socket(s, opts.nozzle_size) && !is_degenerated(inner_path)) {
    let shape2 = offset_n([inner_path], opts.layer_width / 2, 1).slice(1)
    shape = shape1.concat(shape2)
    curves.push(inner_path)
  }
  let lines = scanfn(shape, opts)
  let upsampled_lines = lines.map((l) => upsample_line(l, opts))
  curves = curves.concat(upsampled_lines)
  return curves
}

function upsample_line(line, opts) {
  if (line.length === 1) {
    return line
  }
  let [p1, p2] = line
  let [x1, y1] = p1
  let [x2, y2] = p2
  var result = []
  let d = distance2d(p1, p2)
  let d_min = opts.path.min_ddistance
  let n = Math.ceil(d / d_min) * opts.oversampling
  let t_fine = opts.curve_step / n
  for (var t = 0; t < 1 - t_fine / 2; t += t_fine) {
    let x = linear(x1, x2, t)
    let y = linear(y1, y2, t)
    result.push([x, y])
  }
  result.push(p2)
  return result
}

export function generate_socket_brim(s, height, opts) {
  let thickness = height * opts.brim.layers
  const u = find_u_for_z(s, opts.first_discretize_height)
  let shape_path = gen_path(s, u, opts)
  if (opts.brim.socket === 'none') {
    let socket = make_annotated(
      [shape_path],
      opts.brim.layer_height,
      thickness,
      opts,
      'socket'
    )
    return {
      brim: socket.brim,
      end_height: opts.brim.layer_height,
    }
  }
  let inner_path = []
  if (opts.brim.socket === 'native') {
    inner_path = gen_path(s, 0, without_modifier(opts))
  }
  let curves = []

  if (opts.brim.fill_type == 'offset') {
    curves = curves.concat(gen_offset_curves(shape_path, inner_path, s, opts))
  } else if (
    opts.brim.fill_type == 'scanline' ||
    opts.brim.fill_type == 'scanline90'
  ) {
    curves = curves.concat(gen_scanline_curves(shape_path, inner_path, s, opts))
  }

  let socket = make_annotated(curves, height, thickness, opts, 'socket')
  return {
    brim: socket.brim,
    end_height: socket.end_height,
  }
}

export function generate_brim(s, height, opts) {
  if (opts.brim.rounds === 0) {
    return {
      brim: [],
      end_height: 0,
    }
  }
  let thickness = height
  const u = find_u_for_z(s, opts.first_discretize_height)
  let shape_path = gen_path(s, u, opts)
  let curves = offset([shape_path], opts.layer_width, opts.brim.rounds)
    .slice(1)
    .reverse()
  curves = curves.map((curve) => close_curve(curve))
  curves = connect_curves(curves, opts)
  return make_annotated(curves, height, thickness, opts, 'brim')
}

export function skirt(s, height, opts) {
  if (opts.brim.cleanup_rounds === 0) {
    return {
      brim: [],
      end_height: 0,
    }
  }
  let thickness = height
  const u = find_u_for_z(s, opts.first_discretize_height)
  let shape_path = gen_path(s, u, opts)
  let curve = offset_n(
    [shape_path],
    opts.layer_width * (4 + opts.brim.rounds),
    1
  )[1]
  if (nil(curve)) {
    return {
      brim: [],
      end_height: height,
    }
  }
  let curves = offset_n(
    [curve],
    opts.layer_width,
    opts.brim.cleanup_rounds
  ).slice(1)
  return make_annotated(curves, height, thickness, opts, 'cleanup')
}


export function just_shape(s, height, opts) {
  let thickness = height
  const u = find_u_for_z(s, opts.first_discretize_height)
  let shape_path = gen_path(s, u, opts)
  return make_annotated([shape_path], height, thickness, opts, 'cleanup')
}