import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react'
import { useRef } from 'react'
import { useState, useCallback } from 'react'

export function useStateRef<T>(
  initialValue: T
): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] {
  const [value, setValue] = useState(initialValue)

  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return [value, setValue, ref]
}
