import {
  BackSide,
  CylinderGeometry,
  MeshStandardMaterial,
  SphereGeometry,
  Vector3,
} from 'three'
import { constants, editorTypes } from './constants'
import { CustomCatmullRom, Spline } from './spline-classes'
import { Coordinate } from './utils'

export class Disk {
  x: number
  y: number
  radius: number
  occlusion: number

  constructor(x: number, y: number, radius: number, occlusion = 0.05) {
    this.x = x
    this.y = y
    this.radius = radius
    this.occlusion = occlusion
  }

  getSplinePoint(spline: Spline | CustomCatmullRom) {
    if (spline instanceof Spline)
      return (spline as Spline).curve.getPhysicalPoint(
        1,
        (spline as Spline).modelType.editorType
      )
    else
      return (spline as CustomCatmullRom).getPhysicalPoint(
        1,
        editorTypes.candle
      )
  }

  getViewerCenter() {
    const center = new Vector3(this.x, this.y, 0)
    return Coordinate.convertPhysialCoordinatesToViewer(center)
  }

  getPhysicalCenter() {
    return new Vector3(this.x, this.y, 0)
  }

  getCirclularSection() {
    const circleCenter = this.getPhysicalCenter()

    const circleRadius = this.radius

    return { circleCenter, circleRadius }
  }

  getViewerGeometry(spline: Spline | CustomCatmullRom) {
    const topPoint = this.getSplinePoint(spline)
    const cylinder = new CylinderGeometry(
      this.radius,
      this.radius,
      topPoint.y,
      32,
      1,
      true
    )
    cylinder.rotateX((-1 * Math.PI) / 2)

    const center = this.getPhysicalCenter()
    cylinder.translate(center.x, center.y, topPoint.y / 2)

    cylinder.scale(0.1, 0.1, 0.1)
    cylinder.rotateX(Math.PI / 2)

    return cylinder
  }

  static getViewerMaterial(visibility = false) {
    const newMaterial = new MeshStandardMaterial({
      color: 'pink',
      emissive: 'yellow',
      emissiveIntensity: 1.0,
      metalness: 0.2,
      roughness: 0.5,
      transparent: true,
      visible: visibility,
      opacity: 0.6,
      wireframe: false,
    })

    return newMaterial
  }

  static serialize(obj: Disk) {
    return JSON.stringify([obj.x, obj.y, obj.radius, obj.occlusion])
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)

    return new Disk(jsonObj[0], jsonObj[1], jsonObj[2], jsonObj[3])
  }

  getScaled(newRadius: number) {
    const newX = this.x * (newRadius / this.radius)
    const newY = this.y * (newRadius / this.radius)

    return new Disk(newX, newY, newRadius, this.occlusion)
  }
}
