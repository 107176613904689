import {
  linear,
  length,
  sub,
  complement,
  nil,
  distance,
  add,
  even,
} from './utils2'
import { normal_xy } from './geometry'
import { assoc } from './immutant'
import { eduction } from './itertools'

var t = require('transducers-js')

export const attach_deltas = t.map((p) => {
  let [p1, p2] = p
  let extrusion_factor_changed = !(p1.e_factor === p2.e_factor)

  p2.v = sub(p2.point, p1.point)
  p2.distance = length(p2.v)
  p2.average_layerheight = linear(p1.layer_height, p2.layer_height, 0.5)
  p2.extrusion_factor_changed = extrusion_factor_changed
  return p2
})

function is_shell(p) {
  let not_nil = complement(nil)
  let group = p.group
  if (not_nil(p.group) && group === 'object') {
    return true
  } else {
    return false
  }
}

export function add_volume(nozzle_size, extrusion_multiplier) {
  function _add_volume(p) {
    p.volume =
      p.distance * p.average_layerheight * nozzle_size * extrusion_multiplier
    return p
  }
  return _add_volume
}

export function total_volume(total_start) {
  var total = total_start
  return function (x) {
    total += x.volume
    x.total_print_volume = total
    return x
  }
}

export function discard_on_distance(min, max) {
  function _discard_on_distance(p1, p2, p3) {
    if (p2.required) {
      return false
    }
    let d12 = distance(p1.point, p2.point)
    if (d12 < min) {
      return true
    }
    let d13 = distance(p1.point, p3.point)
    if (d13 < max) {
      return true
    }
    return false
  }
  return _discard_on_distance
}

export function apply_modifier(p, s, opts) {
  let modifier = opts.surface_modifier
  if (modifier.name === 'none') {
    return p
  }
  let frequency_offset = modifier.name === 'Woven' ? 0.5 : 0
  let phase_offset = modifier.name === 'Woven' && even(p.meta.number) ? 0.5 : 0
  let [x, y, z] = normal_xy(s, p.t, p.u)
  let amplitude = modifier.amplitude
  let frequency = modifier.frequency + frequency_offset
  let a_f = Math.sin(Math.PI * 2 * frequency * (p.t + phase_offset))
  let dx = [x * a_f * amplitude, y * a_f * amplitude, 0]
  return assoc(p, { point: add(p.point, dx) })
}

export function without_modifier(opts) {
  return assoc(opts, { surface_modifier: { name: 'none' } })
}

export function get_bbox(paths) {
  return t.reduce(
    function _reduce(result, value) {
      let [x, y, z] = value
      return {
        x: { min: Math.min(result.x.min, x), max: Math.max(result.x.max, x) },
        y: { min: Math.min(result.y.min, y), max: Math.max(result.y.max, y) },
        z: { min: Math.min(result.z.min, z), max: Math.max(result.y.max, y) },
      }
    },
    {
      x: { min: Number.MAX_SAFE_INTEGER, max: 0 },
      y: { min: Number.MAX_SAFE_INTEGER, max: 0 },
      z: { min: Number.MAX_SAFE_INTEGER, max: 0 },
    },
    eduction(
      t.comp(
        t.mapcat(t.identity),
        t.mapcat((x) => [[{ point: x.start }], x.points]),
        t.cat,
        t.map((x) => x.point)
      ),
      paths
    )
  )
}
