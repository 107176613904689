import { map } from 'lodash'
// @ts-ignore
import { ElementSize } from '@zag-js/element-size'
import {
  CatmullRomCurve3,
  CubicBezierCurve3,
  CurvePath,
  Line3,
  LineCurve3,
  Vector,
  Vector2,
  Vector3,
} from 'three'
import { config, smallerXYDimension } from './configs'
import {
  constants,
  ModelType,
  EditorType,
  modelTypes,
  editorTypes,
} from './constants'

/*
A catmull rom implementation. It takes our knots and converts and creates bezier segments
between them.

The logic is fairly simple. Given a set of array vectors, knots:

the in bezier handles of knots[i] would be paraller to knots[i+1] - knots[i-1] for all i except i = 0 and
i = knots[knots.length - 1] i.e. for all knots except the first and the last one.

the magnitude of any "in handle" for knots[i] would be equal to (knots[i] - knots[i-1].length * tension)
similarly, the magnitude of any "out handle" for knots[i]  would be equal to (knots[i+1] - knots[i].length * tension)
again, for all i except first and last knot.

for first and last knot both the in handle and out handle would conincide with the position of the knot itself
*/
export class CustomCatmullRom {
  overhangAngleDegrees: number
  curvePath: CurvePath<Vector3>
  knots: Knot[]
  points: Vector3[]
  isSafe: boolean
  safeKnots: Vector3[]
  safeCurvePath: CurvePath<Vector3>
  resolution: number

  constructor(knots: Knot[], resolution: number, overhangAngleDegrees = 40) {
    this.overhangAngleDegrees = overhangAngleDegrees
    this.resolution = resolution

    let tension = 1 / 3
    let knotVectors = knots.map((knot) => {
      return new Vector3(knot.position.x, knot.position.y, 0)
    })

    if (tension > 0.4)
      throw 'Tension cannnot be above 0.4 to maintain a functioning centripetal catmull rom'

    this.curvePath = new CurvePath()

    let curves = this.initializeCurve(knots, knotVectors, tension)

    this.knots = knots
    this.curvePath.curves = curves

    this.points = []
    this.isSafe = true

    if (this.knots.length > 1) {
      let numberOfPoints = Math.ceil(this.getLength() / resolution)
      this.points = this.getPoints(numberOfPoints)
    }

    this.safeKnots = [knotVectors[0].clone()]

    for (let i = 1; i < this.knots.length; i++) {
      const horizontalVector = new Vector3(1, 0, 0)
      let newSafeKnot = knotVectors[i].clone()
      if (newSafeKnot.y < this.safeKnots[i - 1].y)
        newSafeKnot.y = this.safeKnots[i - 1].y

      const angleBetweenKnots =
        (horizontalVector.angleTo(
          newSafeKnot.clone().sub(this.safeKnots[this.safeKnots.length - 1])
        ) *
          180) /
        Math.PI

      if (angleBetweenKnots < overhangAngleDegrees) {
        const rotatedVector = horizontalVector
          .clone()
          .applyAxisAngle(
            new Vector3(0, 0, 1),
            (overhangAngleDegrees * Math.PI) / 180
          )

        const pointA = this.safeKnots[i - 1]
        const pointB = this.safeKnots[i - 1].clone().add(rotatedVector)

        const slope = (pointB.y - pointA.y) / (pointB.x - pointA.x)
        const yIntercept = pointB.y - slope * pointB.x

        const neededXCoordinate = (newSafeKnot.y - yIntercept) / slope

        //added a small value of 0.001 to make sure that this knot is never the same as the previous knot
        // which causes problems and bugs in the three js library
        newSafeKnot = new Vector3(neededXCoordinate, newSafeKnot.y + 0.01, 0)
      } else if (angleBetweenKnots > 180 - overhangAngleDegrees) {
        const rotatedVector = horizontalVector
          .clone()
          .applyAxisAngle(
            new Vector3(0, 0, 1),
            (-1 * overhangAngleDegrees * Math.PI) / 180
          )

        const pointA = this.safeKnots[i - 1]
        const pointB = this.safeKnots[i - 1].clone().add(rotatedVector)

        const slope = (pointB.y - pointA.y) / (pointB.x - pointA.x)
        const yIntercept = pointB.y - slope * pointB.x

        const neededXCoordinate = (newSafeKnot.y - yIntercept) / slope

        //added a small value of 0.001 to make sure that this knot is never the same as the previous knot
        // which causes problems and bugs in the three js library
        newSafeKnot = new Vector3(neededXCoordinate, newSafeKnot.y + 0.01, 0)
      }

      this.safeKnots.push(newSafeKnot)
    }

    this.safeCurvePath = new CurvePath()

    let safeCurves = this.initializeCurve(knots, this.safeKnots, tension)
    this.safeCurvePath.curves = safeCurves
  }

  initializeCurve(knots: Knot[], knotVectors: Vector3[], tension: number) {
    let curves = []

    for (let i = 0; i < knots.length; i++) {
      let currentKnot = knots[i]
      let currentKnotVector = knotVectors[i]
      if (i == 0) {
        currentKnot.handles = {
          in: currentKnotVector,
          out: currentKnotVector,
        }
        curves.push(
          new CubicBezierCurve3(
            currentKnotVector,
            currentKnotVector,
            currentKnotVector,
            currentKnotVector
          )
        )
      } else if (i == 1) {
        let prevKnot = knots[i - 1]
        let prevKnotVector = knotVectors[i - 1]

        let slopeVector = currentKnotVector.clone().sub(prevKnotVector)

        //Alternative prevKnot outhandle can be: slopeVector.clone().multiplyScalar(tension).add(prevKnotVector)
        prevKnot.handles = { in: prevKnotVector, out: prevKnotVector }
        currentKnot.handles = {
          in: slopeVector
            .clone()
            .multiplyScalar(tension)
            .multiplyScalar(-1)
            .add(currentKnotVector),
          out: currentKnotVector,
        }

        curves[i - 1].v0 = prevKnotVector
        curves[i - 1].v1 = prevKnot.handles.out
        curves[i - 1].v2 = currentKnot.handles.in
        curves[i - 1].v3 = currentKnotVector
      } else {
        let prevKnot = knots[i - 1]
        let prevKnotVector = knotVectors[i - 1]

        let slopeVector = currentKnotVector.clone().sub(knotVectors[i - 2])
        let slopeUnitVector = slopeVector
          .clone()
          .divideScalar(slopeVector.length())

        prevKnot.handles = {
          in: slopeUnitVector
            .clone()
            .multiplyScalar(prevKnotVector.distanceTo(knotVectors[i - 2]))
            .multiplyScalar(tension)
            .multiplyScalar(-1)
            .add(prevKnotVector),
          out: slopeUnitVector
            .clone()
            .multiplyScalar(prevKnotVector.distanceTo(currentKnotVector))
            .multiplyScalar(tension)
            .add(prevKnotVector),
        }

        currentKnot.handles = {
          in: currentKnotVector,
          out: currentKnotVector,
        }

        curves[i - 2].v2 = prevKnot.handles.in

        curves.push(
          new CubicBezierCurve3(
            prevKnotVector,
            prevKnot.handles.out,
            currentKnot.handles.in,
            currentKnotVector
          )
        )
      }
    }

    return curves
  }

  getPoints(numberOfPoints = 80) {
    if (numberOfPoints && this.knots.length > 1)
      return this.curvePath.getSpacedPoints(numberOfPoints)
    else return this.points
  }

  getLength() {
    let curveLengths = this.curvePath.getCurveLengths()

    return curveLengths[curveLengths.length - 1]
  }

  getPhysicalLength(editorType: EditorType) {
    let curveLengths = this.safeCurvePath.getCurveLengths()

    return (
      curveLengths[curveLengths.length - 1] * editorType.getSmallerXYDimension()
    )
  }
  //These are the points that would actually be used to create the geometry, getPoints() only gives points to display in the editor

  getPhysicalPoint(t: number, editorType: EditorType) {
    if (t > 1 || t < 0) throw 'Please enter t value for spline between 0-1'

    return Knot.convertNormalizedPosToPhysicalPos(
      this.safeCurvePath.getPointAt(t),
      editorType
    )
  }

  //Would sample the spline at different points and return sections where overhand is crossed.
  getOverhangSections() {
    let overhangSections = []
    let currentSection = []

    for (let i = 0; i < this.points.length - 1; i++) {
      let diffVec = this.points[i + 1].clone().sub(this.points[i])

      let slope = Number((diffVec.y / diffVec.x).toFixed(2))

      if (
        slope < Math.tan((this.overhangAngleDegrees * Math.PI) / 180) &&
        slope > -1 * Math.tan((this.overhangAngleDegrees * Math.PI) / 180)
      )
        currentSection.push(this.points[i])
      else {
        currentSection.push(this.points[i])
        if (currentSection.length > 1) overhangSections.push(currentSection)

        currentSection = []
      }
    }

    if (currentSection.length != 0) overhangSections.push(currentSection)

    return overhangSections
  }

  serialize() {
    return JSON.stringify({
      knots: this.knots,
      resolution: this.resolution,
      overhangAngleDegrees: this.overhangAngleDegrees,
    })
  }

  static deserialize(string: string) {
    const obj = JSON.parse(string)

    return new CustomCatmullRom(
      obj.knots,
      obj.resolution,
      obj.overhangAngleDegrees
    )
  }
}

export class GeneralCurvePath {
  curvePath: CurvePath<Vector3>

  constructor(curvePath: CurvePath<Vector3>) {
    this.curvePath = curvePath
  }

  getPoints(numberOfPoints: number = 80) {
    return this.curvePath.getSpacedPoints(numberOfPoints)
  }
}

export class GeneralSpline {
  curve: GeneralCurvePath

  constructor(curve: GeneralCurvePath) {
    this.curve = curve
  }
}

export class IneCatmullRom extends GeneralCurvePath {
  knots: IneKnot[]
  cyclic: boolean
  points: Vector3[]
  isSafe: boolean

  constructor(knots: IneKnot[], resolution: number, cyclic = true) {
    let tension = 1 / 3
    let knotVectors = knots.map((knot) => {
      return new Vector3(knot.position.x, knot.position.y, 0)
      //half of the dot diameter is always added because positions generated are from the top
      //left corner of the dot but we want them to be from the center
    })

    if (tension > 0.4)
      throw 'Tension cannnot be above 0.4 to maintain a functioning centripetal catmull rom'

    const curvePath = new CurvePath<Vector3>()
    let curves = IneCatmullRom.initializeCurve(knots, knotVectors, tension)

    curvePath.curves = curves
    super(curvePath)
    this.cyclic = false
    this.knots = knots

    this.points = []
    this.isSafe = true

    if (this.knots.length > 1) {
      let numberOfPoints = Math.ceil(this.getLength() / resolution)
      this.points = this.getPoints(numberOfPoints)
    }
  }

  static initializeCurve(
    givenKnots: IneKnot[],
    givenKnotVectors: Vector3[],
    tension: number
  ) {
    const knots = [...givenKnots]
    const knotVectors = [...givenKnotVectors]

    let curves = []

    for (let i = 0; i < knots.length; i++) {
      let currentKnot = knots[i]
      let currentKnotVector = knotVectors[i]

      let prevKnot = i == 0 ? knots[knots.length - 1] : knots[i - 1]
      let prevKnotVector =
        i == 0 ? knotVectors[knotVectors.length - 1] : knotVectors[i - 1]

      let prevPrevKnot = i == 0 ? knots[knots.length - 2] : knots[i - 2]
      let prevPrevKnotVector =
        i == 0 ? knotVectors[knotVectors.length - 2] : knotVectors[i - 2]

      let nextKnot = i == knots.length - 1 ? knots[0] : knots[i + 1]
      let nextKnotVector =
        i == knots.length - 1 ? knotVectors[0] : knotVectors[i + 1]

      if (currentKnot.type == 0 && nextKnot.type == 1) {
        currentKnot.handles.in = currentKnotVector

        const slopeVector = currentKnotVector.clone().sub(prevKnotVector)

        let slopeUnitVector = slopeVector
          .clone()
          .divideScalar(slopeVector.length())

        currentKnot.handles.out = slopeUnitVector
          .clone()
          .multiplyScalar(nextKnotVector.distanceTo(currentKnotVector))
          .multiplyScalar(tension)
          .add(currentKnotVector)
      } else if (currentKnot.type == 0 && nextKnot.type == 0) {
        currentKnot.handles.in = currentKnotVector
        currentKnot.handles.out = currentKnotVector
      } else if (currentKnot.type == 1 && nextKnot.type == 1) {
        const slopeVector = nextKnotVector.clone().sub(prevKnotVector)

        let slopeUnitVector = slopeVector
          .clone()
          .divideScalar(slopeVector.length())

        currentKnot.handles.in = slopeUnitVector
          .clone()
          .multiplyScalar(prevKnotVector.distanceTo(currentKnotVector))
          .multiplyScalar(tension)
          .multiplyScalar(-1)
          .add(currentKnotVector)

        currentKnot.handles.out = slopeUnitVector
          .clone()
          .multiplyScalar(nextKnotVector.distanceTo(currentKnotVector))
          .multiplyScalar(tension)
          .add(currentKnotVector)
      } else if (currentKnot.type == 1 && nextKnot.type == 0) {
        const slopeVector = nextKnotVector.clone().sub(currentKnotVector)

        let slopeUnitVector = slopeVector
          .clone()
          .divideScalar(slopeVector.length())

        currentKnot.handles.in = slopeUnitVector
          .clone()
          .multiplyScalar(prevKnotVector.distanceTo(currentKnotVector))
          .multiplyScalar(tension)
          .multiplyScalar(-1)
          .add(currentKnotVector)

        currentKnot.handles.out = slopeUnitVector
          .clone()
          .multiplyScalar(nextKnotVector.distanceTo(currentKnotVector))
          .multiplyScalar(tension)
          .add(currentKnotVector)
      }
    }

    for (let i = 0; i < knots.length; i++) {
      let currentKnot = knots[i]
      let currentKnotVector = knotVectors[i]

      let prevKnot = i == 0 ? knots[knots.length - 1] : knots[i - 1]
      let prevKnotVector =
        i == 0 ? knotVectors[knotVectors.length - 1] : knotVectors[i - 1]
      curves.push(
        new CubicBezierCurve3(
          prevKnotVector,
          prevKnot.handles.out,
          currentKnot.handles.in,
          currentKnotVector
        )
      )
    }
    return curves
  }

  getPoints(numberOfPoints: number = 80) {
    if (numberOfPoints && this.knots.length > 1)
      return this.curvePath.getSpacedPoints(numberOfPoints)
    else return this.points
  }

  getLength() {
    let curveLengths = this.curvePath.getCurveLengths()

    return curveLengths[curveLengths.length - 1]
  }

  getPhysicalLength(editorType: EditorType) {
    let curveLengths = this.curvePath.getCurveLengths()

    return (
      curveLengths[curveLengths.length - 1] * editorType.getSmallerXYDimension()
    )
  }
  //These are the points that would actually be used to create the geometry, getPoints() only gives points to display in the editor

  getPhysicalPoint(t: number, editorType: EditorType) {
    if (t > 1 || t < 0) throw 'Please enter t value for spline between 0-1'

    return Knot.convertNormalizedPosToPhysicalPos(
      this.curvePath.getPointAt(t),
      editorType
    )
  }
}

//Class to hold our control points. It stores the positions as nomalized (between 0 and 1) positions so that we can add window resizing logic later.
//Normalisation also makes it easier to deal with the actual measurements of the lamp.
export class Knot {
  position: Vector2
  locked: number
  deletable: boolean
  isLast: boolean
  isActive: boolean

  //handles can be Vector2 or Vector3 but we only need X,Y coordinates so the Z will be ignored
  //The only reason Vector3 is allowed because three js has a lot more fleshed out 3D library than 2D
  handles!: { in: Vector3; out: Vector3 }

  constructor(
    position: Vector2,
    locked: number,
    isLast: boolean,
    isActive: boolean = false,
    deletable: boolean = true
  ) {
    this.position = position
    this.locked = locked //0 = not locked, 1 = y is locked, 2 = x is locked, 3 = z is locked
    this.deletable = deletable
    this.isLast = isLast
    this.isActive = isActive
  }

  static convertEditorPosToNormalizedPos(
    editorSize: ElementSize,
    position: Vector2 | Vector3
  ) {
    let primaryDimension = editorSize.width
    return new Vector2(
      position.x / primaryDimension,
      position.y / primaryDimension
    )
  }

  static convertNormalizedPosToEditorPos(
    editorSize: ElementSize,
    position: Vector2 | Vector3
  ) {
    let primaryDimension = editorSize.width
    return new Vector2(
      position.x * primaryDimension,
      position.y * primaryDimension
    )
  }

  static convertPhysicalPosToNormalizedPos(
    position: Vector2 | Vector3,
    editorType: EditorType
  ): Vector2 {
    return new Vector2(
      position.x / editorType.getSmallerXYDimension(),
      position.y / editorType.getSmallerXYDimension()
    )
  }

  static convertNormalizedPosToPhysicalPos(
    position: Vector2 | Vector3,
    editorType: EditorType
  ) {
    return new Vector2(
      position.x * editorType.getSmallerXYDimension(),
      position.y * editorType.getSmallerXYDimension()
    )
  }

  static convertEditorPosToPhysicalPos(
    editorSize: ElementSize,
    position: Vector2 | Vector2,
    editorType: EditorType
  ) {
    const divisor = editorSize.width
    const point = new Vector3(position.x, position.y, 0)
    point
      .divideScalar(divisor)
      .multiplyScalar(editorType.getSmallerXYDimension())

    return point
  }

  static convertPhysicalPosToEditorPos(
    editorSize: ElementSize,
    position: Vector2 | Vector2,
    editorType: EditorType
  ) {
    const point = new Vector3(position.x, position.y, 0)
    const divisor = editorSize.width
    point
      .divideScalar(editorType.getSmallerXYDimension())
      .multiplyScalar(divisor)

    return point
  }

  updatePosition(position: Vector2 | Vector2) {
    switch (this.locked) {
      case 0:
        this.position = position
        break
      case 1:
        this.position.x = position.x
        break
      case 2:
        this.position.y = position.y
        break
      default:
        break
    }
  }

  clone() {
    const knot = new Knot(
      this.position,
      this.locked,
      this.isLast,
      this.isActive,
      this.deletable
    )

    return knot
  }

  static deserialize(string: string): Knot {
    const jsonObj = JSON.parse(string)
    const position = new Vector2(jsonObj[0][0], jsonObj[0][1])
    const knot = new Knot(
      position,
      jsonObj[1],
      jsonObj[2],
      jsonObj[3],
      jsonObj[4]
    )

    return knot
  }

  serialize(): string {
    const newKnot = this.clone()

    newKnot.position.x = Number(newKnot.position.x.toFixed(3))
    newKnot.position.y = Number(newKnot.position.y.toFixed(3))

    return JSON.stringify([
      [newKnot.position.x, newKnot.position.y],
      newKnot.locked,
      newKnot.isLast,
      newKnot.isActive,
      newKnot.deletable,
    ])
  }
}

export class Spline {
  knots: Knot[]
  modelType: ModelType
  resolution: number
  curve: CustomCatmullRom
  overhangAngleDegrees: number

  constructor(
    knots: Knot[],
    modelType: ModelType,
    overhangAngleDegrees: number = 40,
    resolution: number = 0.001
  ) {
    this.overhangAngleDegrees = overhangAngleDegrees
    if (modelType.id == modelTypes.leroyMerlin.id) {
      if (knots.length > 0)
        knots[0] = new Knot(
          Knot.convertPhysicalPosToNormalizedPos(
            new Vector2(21.3 * 2, 63.4),
            modelType.editorType
          ),
          3,
          knots[0].isLast,
          knots[0].isActive,
          false
        )
    }

    if (
      knots.length > 0 &&
      (modelType.id == modelTypes.e27.id ||
        modelType.id == modelTypes.bigLamp.id ||
        modelType.id == modelTypes.stacker.id ||
        modelType.id == modelTypes.vase.id)
    ) {
      knots[0].locked = 0
      knots[0].deletable = true
    }

    if (
      knots[knots.length - 1].position.y - knots[0].position.y <
        modelType.minHeight / modelType.editorType.getSmallerXYDimension() &&
      (modelType.id == modelTypes.e27.id ||
        modelType.id == modelTypes.leroyMerlin.id ||
        modelType.id == modelTypes.bigLamp.id ||
        modelType.id == modelTypes.stacker.id ||
        modelType.id == modelTypes.vase.id ||
        modelType.id === modelTypes.smallVase.id)
    ) {
      knots[knots.length - 1].position.y =
        knots[0].position.y +
        modelType.minHeight / modelType.editorType.getSmallerXYDimension()

      if (knots[knots.length - 1].position.y > 1)
        knots[knots.length - 1].position.y = 1

      if (
        knots[knots.length - 1].position.y - knots[0].position.y <
        modelType.minHeight / modelType.editorType.getSmallerXYDimension()
      )
        knots[0].position.y =
          knots[knots.length - 1].position.y -
          modelType.minHeight / modelType.editorType.getSmallerXYDimension()
    }

    if (
      modelType.editorType.id == editorTypes.lamp.id ||
      modelType.id == modelTypes.bigLamp.id ||
      modelType.id == modelTypes.stacker.id ||
      modelType.id == modelTypes.vase.id ||
      modelType.id == modelTypes.smallVase.id
    )
      knots.forEach((knot) => {
        if (
          knot.position.x <
          (modelType.socketSize + modelType.flankSize) /
            modelType.editorType.getSmallerXYDimension()
        )
          knot.position.x =
            (modelType.socketSize + modelType.flankSize) /
            modelType.editorType.getSmallerXYDimension()
      })
    else if (modelType.id == modelTypes.pablo.id) {
      knots.forEach((knot) => {
        if (knot.position.x < 0.3) knot.position.x = 0.3
      })
    }

    if (
      knots.length > 0 &&
      knots[0].position.x <
        (modelType.socketSize + modelType.flankSize) /
          modelType.editorType.getSmallerXYDimension()
    ) {
      knots[0].position.x =
        (modelType.socketSize + modelType.flankSize) /
        modelType.editorType.getSmallerXYDimension()
    }

    if (knots.length >= 2 && modelType.id == modelTypes.pablo.id) {
      knots[knots.length - 1].position.x < knots[0].position.x
        ? (knots[knots.length - 1].position.x = knots[0].position.x)
        : knots[knots.length - 1].position.x

      knots[knots.length - 1].position.y < 0.6
        ? (knots[knots.length - 1].position.y = 0.6)
        : null
    }

    this.knots = knots
    this.curve = this.createCurve(overhangAngleDegrees, resolution)
    this.resolution = resolution
    this.modelType = modelType
  }

  createCurve(overhangAngleDegrees: number, resolution: number) {
    return new CustomCatmullRom(this.knots, resolution, overhangAngleDegrees)
  }

  getPoints() {
    const points = this.curve.getPoints()
    return points
  }

  getPolylineString(editorSize: ElementSize) {
    let points = this.getPoints().map((point) =>
      Knot.convertNormalizedPosToEditorPos(editorSize, point)
    )

    if (points != undefined) {
      let polylineString = points.reduce(
        (prevValue: string, currentValue: Vector2) =>
          prevValue + currentValue.x + ' ' + currentValue.y + ', ',
        ''
      )

      return polylineString.slice(0, -2)
    }
  }

  getSafePolylineString(editorSize: ElementSize) {
    let points = this.curve.safeCurvePath
      .getSpacedPoints(80)
      .map((point) => Knot.convertNormalizedPosToEditorPos(editorSize, point))

    if (points != undefined) {
      let polylineString = points.reduce(
        (prevValue: string, currentValue: Vector2) =>
          prevValue + currentValue.x + ' ' + currentValue.y + ', ',
        ''
      )

      return polylineString.slice(0, -2)
    }
  }

  getOverhangPolylineStrings(editorSize: ElementSize) {
    const overhangSections = this.curve.getOverhangSections()

    return overhangSections.map((points) => {
      let polylineString = points
        .map((point) => Knot.convertNormalizedPosToEditorPos(editorSize, point))
        .reduce(
          (prevValue, currentValue) =>
            prevValue + currentValue.x + ' ' + currentValue.y + ', ',
          ''
        )

      return polylineString.slice(0, -2)
    })
  }

  static deserialize(string: string) {
    const jsonObj = JSON.parse(string)

    const knots = jsonObj[0].map((knot: string) => {
      return Knot.deserialize(knot)
    })

    const modelType = modelTypes[jsonObj[1] as keyof typeof modelTypes]
    const resolution = jsonObj[2] as number
    const overhangAngleDegrees = jsonObj[3] ? (jsonObj[3] as number) : 40

    return new Spline(knots, modelType, overhangAngleDegrees, resolution)
  }

  static serialize(obj: Spline) {
    return JSON.stringify([
      obj.knots.map((knot) => knot.serialize()),
      obj.modelType.id,
      obj.resolution,
      obj.overhangAngleDegrees,
    ])
  }
}

export class IneKnot extends Knot {
  type: number

  constructor(
    position: Vector2,
    locked: number,
    isLast: boolean,
    type: number,
    isActive = false,
    deletable = true
  ) {
    super(position, locked, isLast, isActive, deletable)
    this.type = type //0 for straight line and 1 for curve
    this.handles = {
      in: new Vector3(position.x, position.y, 0),
      out: new Vector3(position.x, position.y, 0),
    }
  }

  static deserialize(string: string) {
    const jsonObj = JSON.parse(string)
    const position = new Vector2(jsonObj[0][0], jsonObj[0][1])

    const knot = new IneKnot(
      position,
      jsonObj[1],
      jsonObj[2],
      jsonObj[3],
      jsonObj[4],
      jsonObj[5]
    )

    return knot
  }

  serialize() {
    const newKnot = this.clone()

    newKnot.position.x = Number(newKnot.position.x.toFixed(3))
    newKnot.position.y = Number(newKnot.position.y.toFixed(3))

    return JSON.stringify([
      [newKnot.position.x, newKnot.position.y],
      newKnot.locked,
      newKnot.isLast,
      this.type,
      newKnot.isActive,
      newKnot.deletable,
    ])
  }
}

export class IneSpline extends GeneralSpline {
  knots: IneKnot[]
  modelType: ModelType
  resolution: number
  isSafe: boolean

  constructor(
    knots: IneKnot[],
    modelType: ModelType,
    curve?: GeneralCurvePath,
    resolution = 0.01
  ) {
    if (curve) {
      super(curve)
      this.knots = []
    } else {
      const curve = IneSpline.createCurve(knots, resolution)
      super(curve)
      this.knots = knots
    }
    this.modelType = modelType
    this.isSafe = true
    this.resolution = resolution
  }

  static createCurve(knots: IneKnot[], resolution: number) {
    return new IneCatmullRom(knots, resolution)
  }

  getPoints() {
    const points = this.curve.getPoints()
    return points
  }

  getPolylineString(editorSize: ElementSize) {
    let points = this.getPoints().map((point) =>
      Knot.convertNormalizedPosToEditorPos(editorSize, point)
    )

    if (points != undefined) {
      let polylineString = points.reduce(
        (prevValue, currentValue) =>
          prevValue + currentValue.x + ' ' + currentValue.y + ', ',
        ''
      )

      return polylineString.slice(0, -2)
    }
  }

  static deserialize(string: string) {
    const jsonObj = JSON.parse(string)

    const knots = jsonObj[0].map((knot: string) => {
      return IneKnot.deserialize(knot)
    })
    return new IneSpline(knots, jsonObj[1], jsonObj[2])
  }

  static serialize(obj: IneSpline) {
    return JSON.stringify([
      obj.knots.map((knot) => knot.serialize()),
      obj.modelType,
      undefined,
      obj.resolution,
    ])
  }
}

export class CustomShapeKnot {
  index: number
  position: Vector2

  constructor(index: number, position: Vector2) {
    this.position = position
    this.index = index
  }

  static serialize(obj: CustomShapeKnot) {
    return JSON.stringify([obj.index, obj.position])
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)
    return new CustomShapeKnot(jsonObj[0], jsonObj[1])
  }

  static convertNormalizedPosToEditorPos(
    editorSize: ElementSize,
    position: Vector2 | Vector3
  ) {
    if (editorSize != undefined) {
      let primaryDimension = editorSize.width
      const multiplier =
        (0.833333 * primaryDimension - primaryDimension / 2) / 40
      return {
        x: position.x * multiplier + primaryDimension / 2,
        y: position.y * multiplier + primaryDimension / 2,
      }
    }
  }

  static convertEditorPosToNormalizedPos(
    editorSize: ElementSize,
    position: Vector2 | Vector3
  ) {
    let primaryDimension = editorSize.width
    const divisor = (0.833333 * primaryDimension - primaryDimension / 2) / 40
    return new Vector2(
      (position.x - primaryDimension / 2) / divisor,
      (position.y - primaryDimension / 2) / divisor
    )
  }
}

export class CustomShapeSpline {
  knots: CustomShapeKnot[]
  totalKnots: number
  curve: CatmullRomCurve3

  static splineFromNumberOfKnots(totalKnots: number) {
    let knots = []

    for (let i = 0; i < totalKnots; i++) {
      const theta = (i * 2 * Math.PI) / totalKnots - Math.PI / 2

      knots.push(
        new CustomShapeKnot(
          i,
          new Vector2(40 * Math.cos(theta), 40 * Math.sin(theta))
        )
      )
    }

    return new CustomShapeSpline(knots)
  }

  constructor(knots: CustomShapeKnot[]) {
    this.knots = knots
    this.totalKnots = knots.length
    this.curve = this.createCurve()
  }

  createCurve() {
    const vertices = this.knots.reduce((prevVertices, knot) => {
      return [...prevVertices, new Vector3(knot.position.x, knot.position.y, 0)]
    }, [] as Vector3[])

    return new CatmullRomCurve3(vertices, true)
  }

  static serialize(obj: CustomShapeSpline) {
    return JSON.stringify(obj.knots.map(CustomShapeKnot.serialize))
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)

    return new CustomShapeSpline(jsonObj.map(CustomShapeKnot.deserialize))
  }
}

export const getInitalKnotState = (editorTypeID: string) => {
  let newKnots = []

  switch (editorTypeID) {
    case editorTypes.lamp.id:
      newKnots = [
        new Knot(new Vector2(0, 0), 0, false, false, true),
        new Knot(new Vector2(0.25, 0.35), 0, false, false, true),
        new Knot(new Vector2(0.4, 0.6), 0, false, false, true),
      ]
      break

    case editorTypes.bigLamp.id:
      newKnots = [
        new Knot(new Vector2(0, 0), 0, false, false, true),
        new Knot(new Vector2(0.25, 0.35), 0, false, false, true),
        new Knot(new Vector2(0.4, 0.6), 0, false, false, true),
      ]
      break

    case editorTypes.pablo.id:
      newKnots = [
        new Knot(new Vector2(0.72, 0), 1, false, false, false),
        new Knot(new Vector2(0.72, 0.166), 0, false, false, true),
        new Knot(new Vector2(0.665, 0.206), 0, false, false, true),
        new Knot(new Vector2(0.383, 0.315), 0, false, false, true),
        new Knot(new Vector2(0.72, 0.743), 0, false, false, true),

        new Knot(new Vector2(0.72, 0.84), 0, false, false, false),
      ]
      break

    case editorTypes.boucle.id:
      newKnots = [
        new Knot(new Vector2(0.02, 0), 1, false, false, true),
        new Knot(new Vector2(0.02, 0.84), 1, false, false, true),
      ]
      break

    case editorTypes.sketch.id:
      newKnots = [
        new Knot(new Vector2(0.01, 0), 1, false, false, true),
        new Knot(new Vector2(0.01, 0.84), 1, false, false, true),
      ]
      break

    case editorTypes.bubble.id:
      newKnots = [
        new Knot(new Vector2(0.35, 0), 1, false, false, true),
        new Knot(new Vector2(0.35, 0.84), 1, false, false, true),
      ]
      break

    case editorTypes.stacker.id:
      newKnots = [
        new Knot(new Vector2(0, 0), 0, false, false, true),
        new Knot(new Vector2(0.25, 0.35), 0, false, false, true),
        new Knot(new Vector2(0.4, 0.6), 0, false, false, true),
      ]
      break

    default:
      newKnots = [
        new Knot(new Vector2(0.35, 0), 0, false, false, true),
        new Knot(new Vector2(0.35, 0.84), 0, false, false, true),
      ]
      break
  }

  return newKnots
}

export const getInitialSpline = (modelType: ModelType) => {
  let newKnots = getInitalKnotState(modelType.editorType.id)

  if (modelType.id !== modelTypes.heart_2.id)
    return new Spline(newKnots, modelType)
  else return new Spline(newKnots, modelType, 5)
}
