var t = require('transducers-js')
var Deque = require('double-ended-queue')

// modified version of cognitects partitionAll js transducer. added step parameter
var transducers = {}
/**
 * @constructor
 * @implements {com.cognitect.transducers.ITransformer}
 */
transducers.PartitionAll = function (n, step, xf) {
  this.n = n
  this.step = step
  this.xf = xf
  this.a = new Deque(n)
  this.gap = 0
}
transducers.PartitionAll.prototype['@@transducer/init'] = function () {
  return this.xf['@@transducer/init']()
}
transducers.PartitionAll.prototype['@@transducer/result'] = function (result) {
  if (this.a._length > 0) {
    result = t.unreduced(this.xf['@@transducer/step'](result, this.a.toArray()))
    this.a.clear()
  }
  return this.xf['@@transducer/result'](result)
}
transducers.PartitionAll.prototype['@@transducer/step'] = function (
  result,
  input
) {
  if (this.gap > 0) {
    this.gap -= 1
    return result
  }
  this.a.push(input)
  if (this.n == this.a._length) {
    var a = this.a.toArray()
    for (var i = 0; i < this.step; i++) {
      this.a.shift()
    }
    this.gap = this.step - this.n
    return this.xf['@@transducer/step'](result, a)
  } else {
    return result
  }
}

/**
 * A partitioning transducer. Collects inputs into
 * arrays of size N.
 * @method transducers.partitionAll
 * @param {Number} n an integer
 * @return {com.cognitect.transducers.PartitionAll} a partitionAll transducer
 * @example
 *     var t = transducers;
 *     var xf = t.partitionAll(3);
 *     t.into([], xf, [0,1,2,3,4,5]); // [[0,1,2],[3,4,5]]
 */
export function partitionAll(n, step = null) {
  if (step === null) {
    step = n
  }
  if (t.TRANSDUCERS_DEV && typeof n != 'number') {
    throw new Error('partitionAll must be given a number')
  } else {
    return function (xf) {
      return new transducers.PartitionAll(n, step, xf)
    }
  }
}
