import { useBreakpointValue } from '@chakra-ui/react'
import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react'
import { BoxHelper, Mesh, Scene, Vector3 } from 'three'
import { listOfPickerColors, ModelType, modelTypes } from './constants'
import { useStateRef } from './custom-hooks'
import { Disk } from './disk'
import { IneShape, MeshMaker, ShapeCurve } from './mesh-maker'
import { getInitialShapes, SurfaceModifierType } from './shape-classes'
import { Sphere } from './sphere'
import { IneSpline, Spline, getInitialSpline } from './spline-classes'

export interface ProfilesType {
  startProfile: ShapeCurve
  endProfile: ShapeCurve
  intermediateShapes: ShapeCurve[]
}

export interface GeometryStateType {
  spline: Spline
  color: string
  modelType: ModelType
  profiles: ProfilesType
  spheres: Sphere[]
  disks: Disk[]
  surfaceModifier: SurfaceModifierType
  additionalOptions: { topLipWidth: number; bottomLipWidth: number }
  flipped?: boolean
}

interface SerializedGeometryStateType {
  spline: string
  color: string
  modelType: keyof typeof modelTypes
  profiles: string
  spheres: string
  disks: string
  surfaceModifier: string
  additionalOptions: string
  flipped: boolean
}

/*this should contain all the data that we need to generate the geometry of the object*/
export class GeometryState {
  previousStates: GeometryStateType[]
  setPreviousStates: Dispatch<SetStateAction<GeometryStateType[]>>
  previousStatesRef: MutableRefObject<GeometryStateType[]>

  spline: Spline
  setSpline: (
    value: Spline | ((prevValue: Spline) => Spline),
    addToPrevious?: boolean
  ) => void
  splineRef: MutableRefObject<Spline>

  color: string
  setColor: (
    value: string | ((prevValue: string) => string),
    addToPrevious?: boolean
  ) => void

  modelType: ModelType
  setModelType: (
    value: ModelType | ((prevValue: ModelType) => ModelType),
    addToPrevious?: boolean
  ) => void

  profiles: ProfilesType
  setProfiles: (
    value: ProfilesType | ((prevValue: ProfilesType) => ProfilesType),
    addToPrevious?: boolean
  ) => void

  spheres: Sphere[]
  setSpheres: (
    value: Sphere[] | ((prevValue: Sphere[]) => Sphere[]),
    addToPrevious?: boolean
  ) => void
  spheresRef: MutableRefObject<Sphere[]>

  disks: Disk[]
  setDisks: (
    value: Disk[] | ((prevValue: Disk[]) => Disk[]),
    addToPrevious?: boolean
  ) => void
  disksRef: MutableRefObject<Disk[]>

  surfaceModifier: SurfaceModifierType
  setSurfaceModifier: (
    value:
      | SurfaceModifierType
      | ((prevValue: SurfaceModifierType) => SurfaceModifierType),
    addToPrevious?: boolean
  ) => void

  additionalOptions: { topLipWidth: number; bottomLipWidth: number }
  setAdditionalOptions: (
    value:
      | { topLipWidth: number; bottomLipWidth: number }
      | ((prevValue: { topLipWidth: number; bottomLipWidth: number }) => {
          topLipWidth: number
          bottomLipWidth: number
        }),
    addToPrevious?: boolean
  ) => void

  flipped: boolean
  setFlipped: (
    value: boolean | ((prevValue: boolean) => boolean),
    addToPrevious?: boolean
  ) => void
  flippedRef: MutableRefObject<boolean>

  constructor(modelType: ModelType, serializedState?: string) {
    const initialState = GeometryState.generateInitialState(modelType)

    const [previousStates, setPreviousStates, previousStatesRef] = useStateRef(
      [] as GeometryStateType[]
    )
    this.previousStates = previousStates
    this.setPreviousStates = setPreviousStates
    this.previousStatesRef = previousStatesRef

    const [spline, setSpline, splineRef] = useStateRef(initialState.spline)
    this.spline = spline
    this.setSpline = this.stateSetter(setSpline)
    this.splineRef = splineRef

    const [color, setColor] = useState(initialState.color)
    this.color = color
    this.setColor = this.stateSetter(setColor)

    const [modelTypeState, setModelTypeState] = useState(initialState.modelType)
    this.modelType = modelTypeState
    this.setModelType = this.stateSetter(setModelTypeState)

    const [profiles, setProfiles] = useState(initialState.profiles)
    this.profiles = profiles
    this.setProfiles = this.stateSetter(setProfiles)

    const [spheres, setSpheres, spheresRef] = useStateRef(initialState.spheres)
    this.spheres = spheres
    this.setSpheres = this.stateSetter(setSpheres)
    this.spheresRef = spheresRef

    const [disks, setDisks, disksRef] = useStateRef(initialState.disks)
    this.disks = disks
    this.setDisks = this.stateSetter(setDisks)
    this.disksRef = disksRef

    const [surfaceModifier, setSurfaceModifier] = useState(
      initialState.surfaceModifier
    )
    this.surfaceModifier = surfaceModifier
    this.setSurfaceModifier = this.stateSetter(setSurfaceModifier)

    const [additionalOptions, setAdditionalOptions] = useState(
      initialState.additionalOptions
    )
    this.additionalOptions = additionalOptions
    this.setAdditionalOptions = this.stateSetter(setAdditionalOptions)

    const [flipped, setFlipped, flippedRef] = useStateRef(false)
    this.flipped = flipped
    this.setFlipped = this.stateSetter(setFlipped)
    this.flippedRef = flippedRef
  }

  exportState(): GeometryStateType {
    return {
      spline: this.spline,
      color: this.color,
      modelType: this.modelType,
      profiles: this.profiles,
      spheres: this.spheres,
      disks: this.disks,
      surfaceModifier: this.surfaceModifier,
      additionalOptions: this.additionalOptions,
      flipped: this.flipped,
    }
  }

  importState(state: GeometryStateType) {
    this.setSpline(state.spline, false)
    this.setColor(state.color, false)
    this.setModelType(state.modelType, false)
    this.setProfiles(state.profiles, false)
    this.setSpheres(state.spheres, false)
    this.setDisks(state.disks, false)
    this.setSurfaceModifier(state.surfaceModifier, false)
    this.setAdditionalOptions(state.additionalOptions, false)
    this.setFlipped(state.flipped ? state.flipped : false, false)
  }

  putCurrentStateInPreviousStates() {
    const currentState = this.exportState()
    this.setPreviousStates((previousValue) => {
      if (previousValue.length > 30)
        return [...previousValue.slice(1), currentState]
      else return [...this.previousStates, currentState]
    })
  }

  stateSetter<T>(setFunction: Dispatch<SetStateAction<T>>) {
    return (
      value: T | ((prevValue: T) => T),
      addToPrevious: boolean = true
    ) => {
      if (addToPrevious) {
        this.putCurrentStateInPreviousStates()
      }

      setFunction(value)
    }
  }

  static generateInitialState(
    modelType: ModelType,
    spline?: Spline,
    color?: string,
    profiles?: {
      startProfile: ShapeCurve
      endProfile: ShapeCurve
      intermediateShapes: ShapeCurve[]
    },
    spheres?: Sphere[],
    disks?: Disk[],
    surfaceModifier?: SurfaceModifierType,
    additionalOptions?: { topLipWidth: number; bottomLipWidth: number },
    flipped?: boolean
  ): GeometryStateType {
    return {
      spline: spline ? spline : getInitialSpline(modelType),
      color: color ? color : listOfPickerColors[0],
      modelType: modelType,
      profiles: profiles ? profiles : getInitialShapes(modelType),
      spheres: spheres ? spheres : ([] as Sphere[]),
      disks: disks ? disks : ([] as Disk[]),
      surfaceModifier: surfaceModifier ? surfaceModifier : { name: 'none' },
      additionalOptions: additionalOptions
        ? additionalOptions
        : {
            topLipWidth: 20,
            bottomLipWidth: 20,
          },
      flipped: flipped ? flipped : false,
    }
  }

  resetState(modelType: ModelType) {
    const initialState = GeometryState.generateInitialState(modelType)
    this.putCurrentStateInPreviousStates()

    this.setSpline(initialState.spline, false)
    this.setColor(initialState.color, false)
    this.setModelType(initialState.modelType, false)
    this.setProfiles(initialState.profiles, false)
    this.setSpheres(initialState.spheres, false)
    this.setDisks(initialState.disks, false)
    this.setSurfaceModifier(initialState.surfaceModifier, false)
    this.setAdditionalOptions(initialState.additionalOptions, false)
    this.setFlipped(initialState.flipped!)
  }

  undoState() {
    if (this.previousStatesRef.current.length > 1) {
      const state =
        this.previousStatesRef.current[
          this.previousStatesRef.current.length - 1
        ]
      this.setPreviousStates((prevValue) => {
        const newValue = [...prevValue]
        newValue.pop()
        return newValue
      })

      this.importState(state)
    }
  }

  static serializeExportedState(
    obj: GeometryStateType,
    uuid?: string,
    name?: string
  ) {
    return {
      _id: uuid,
      name: name,
      spline: Spline.serialize(obj.spline),
      color: obj.color,
      modelType: obj.modelType.id,
      profiles: JSON.stringify({
        startProfile: ShapeCurve.serialize(obj.profiles.startProfile),
        endProfile: ShapeCurve.serialize(obj.profiles.endProfile),
        intermediateShapes: obj.profiles.intermediateShapes.map(
          ShapeCurve.serialize
        ),
      }),
      spheres: JSON.stringify(
        obj.spheres.map((sphere) => Sphere.serialize(sphere))
      ),
      disks: JSON.stringify(obj.spheres.map((disk) => Sphere.serialize(disk))),
      surfaceModifier: JSON.stringify(obj.surfaceModifier),
      additionalOptions: JSON.stringify(obj.additionalOptions),
      flipped: obj.flipped,
    }
  }

  getSerializedState(uuid?: string, name?: string) {
    const serializedState = GeometryState.serializeExportedState(
      this,
      uuid,
      name
    )
    return JSON.stringify(serializedState)
  }

  deserializeState(jsonString: string) {
    const parsedState = GeometryState.convertStringToGeometryState(jsonString)
    this.importState(parsedState)
  }

  static convertJSONToGeometryState2(jsonObj: SerializedGeometryStateType): GeometryStateType{
    const profilesObj = JSON.parse(jsonObj.profiles)
    return {
      spline: Spline.deserialize(jsonObj.spline),
      color: jsonObj.color,
      modelType: modelTypes[jsonObj.modelType],
      profiles: {
        startProfile: ShapeCurve.deserialize(profilesObj.startProfile),
        endProfile: ShapeCurve.deserialize(profilesObj.endProfile),
        intermediateShapes: profilesObj.intermediateShapes.map(
          ShapeCurve.deserialize
        ),
      },
      spheres: JSON.parse(jsonObj.spheres).map(Sphere.deserialize),
      disks: JSON.parse(jsonObj.disks).map(Disk.deserialize),
      surfaceModifier: JSON.parse(jsonObj.surfaceModifier),
      additionalOptions: JSON.parse(jsonObj.additionalOptions),
      flipped: jsonObj.flipped,
    }
  }

  static convertStringToGeometryState(jsonString: string): GeometryStateType {
    const jsonObj: SerializedGeometryStateType = JSON.parse(jsonString)
    return GeometryState.convertJSONToGeometryState2(jsonObj)
  }
}

/*Any state that concerns only the website's current state and not the geometry goes here. It is ephemeral and gets reset when the website is reloaded*/
export class WebsiteState {
  hasMounted: boolean
  setHasMounted: Dispatch<SetStateAction<boolean>>

  infoMode: boolean
  setInfoMode: Dispatch<SetStateAction<boolean>>

  numericalMode: boolean
  setNumericalMode: Dispatch<SetStateAction<boolean>>

  sidebarIsOpen: boolean
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>

  selectedSphere: number
  setSelectedSphere: Dispatch<SetStateAction<number>>
  selectedSphereRef: MutableRefObject<number>

  lampViewerEditable: boolean
  setLampViewerEditable: Dispatch<SetStateAction<boolean>>

  modelBeingUpdated: boolean
  setModelBeingUpdated: Dispatch<SetStateAction<boolean>>

  hideModifier: boolean
  setHideModifier: Dispatch<SetStateAction<boolean>>

  randomSalt: number
  setRandomSalt: Dispatch<SetStateAction<number>>

  showLoader: boolean
  setShowLoader: Dispatch<SetStateAction<boolean>>

  showTutorialPopup: boolean
  setShowTutorialPopup: Dispatch<SetStateAction<boolean>>

  zRotationMultiplier: number
  setZRotationMultiplier: Dispatch<SetStateAction<number>>

  scene: Scene
  setScene: Dispatch<SetStateAction<Scene>>

  meshObject: Mesh[]
  setMeshObject: Dispatch<SetStateAction<Mesh[]>>

  boundingBox: {
    boxHelper: BoxHelper
    minVector: Vector3
    maxVector: Vector3
  }[]
  setBoundingBox: Dispatch<
    SetStateAction<
      {
        boxHelper: BoxHelper
        minVector: Vector3
        maxVector: Vector3
      }[]
    >
  >

  gridSizeMultiplier: number
  setGridSizeMultiplier: Dispatch<SetStateAction<number>>

  showGcodeOptions: boolean
  setShowGcodeOptions: Dispatch<SetStateAction<boolean>>

  isLandscape?: boolean

  exportedObjects: GeometryStateType[]
  setExportedObjects: Dispatch<SetStateAction<GeometryStateType[]>>

  selectedObjectIndex: number
  setSelectedObjectIndex: Dispatch<SetStateAction<number>>
  selectedObjectIndexRef: MutableRefObject<number>

  constructor() {
    const [hasMounted, setHasMounted] = useState(false)
    this.hasMounted = hasMounted
    this.setHasMounted = setHasMounted

    const [infoMode, setInfoMode] = useState(true)
    this.infoMode = infoMode
    this.setInfoMode = setInfoMode

    const [numericalMode, setNumericalMode] = useState(false)
    this.numericalMode = numericalMode
    this.setNumericalMode = setNumericalMode

    const [siderbarIsOpen, setSidebarIsOpen] = useState(true)
    this.sidebarIsOpen = siderbarIsOpen
    this.setSidebarIsOpen = setSidebarIsOpen

    const [selectedSphere, setSelectedSphere, selectedSphereRef] =
      useStateRef(-1)
    this.selectedSphere = selectedSphere
    this.setSelectedSphere = setSelectedSphere
    this.selectedSphereRef = selectedSphereRef

    const [lampViewerEditable, setLampViewerEditable] = useState(true)
    this.lampViewerEditable = lampViewerEditable
    this.setLampViewerEditable = setLampViewerEditable

    const [modelBeingUpdated, setModelBeingUpdated] = useState(false)
    this.modelBeingUpdated = modelBeingUpdated
    this.setModelBeingUpdated = setModelBeingUpdated

    const [hideModifier, setHideModifier] = useState(false)
    this.hideModifier = hideModifier
    this.setHideModifier = setHideModifier

    const [randomSalt, setRandomSalt] = useState(1)
    this.randomSalt = randomSalt
    this.setRandomSalt = setRandomSalt

    const [showLoader, setShowLoader] = useState(false)
    this.showLoader = showLoader
    this.setShowLoader = setShowLoader

    const [showTutorialPopup, setShowTutorialPopup] = useState(false)
    this.showTutorialPopup = showTutorialPopup
    this.setShowTutorialPopup = setShowTutorialPopup

    const [zRotationMultiplier, setZRotationMultiplier] = useState(0)
    this.zRotationMultiplier = zRotationMultiplier
    this.setZRotationMultiplier = setZRotationMultiplier

    const [scene, setScene] = useState(new Scene())
    this.scene = scene
    this.setScene = setScene

    const [meshObject, setMeshObject] = useState([new Mesh()])
    this.meshObject = meshObject
    this.setMeshObject = setMeshObject

    const [boundingBox, setBoundingBox] = useState([
      {
        boxHelper: new BoxHelper(new Mesh()),
        minVector: new Vector3(0, 0, 0),
        maxVector: new Vector3(0, 0, 0),
      },
    ])
    this.boundingBox = boundingBox
    this.setBoundingBox = setBoundingBox

    this.isLandscape = useBreakpointValue({ base: false, md: true })

    const [showGcodeOptions, setShowGcodeOptions] = useState(false)
    this.showGcodeOptions = showGcodeOptions
    this.setShowGcodeOptions = setShowGcodeOptions

    const [gridSizeMultiplier, setGridSizeMultiplier] = useState(1)
    this.gridSizeMultiplier = gridSizeMultiplier
    this.setGridSizeMultiplier = setGridSizeMultiplier

    const [exportedObjects, setExportedObjects] = useState<GeometryStateType[]>(
      []
    )
    this.exportedObjects = exportedObjects
    this.setExportedObjects = setExportedObjects

    const [
      selectedObjectIndex,
      setSelectedObjectIndex,
      selectedObjectIndexRef,
    ] = useStateRef(0)
    this.selectedObjectIndex = selectedObjectIndex
    this.setSelectedObjectIndex = setSelectedObjectIndex
    this.selectedObjectIndexRef = selectedObjectIndexRef
  }

  toggleSidebarOpen() {
    this.setSidebarIsOpen((v) => !v)
  }
}

export interface StateProps {
  geometryState: GeometryState
  websiteState: WebsiteState
}

export function getAllObjectsSerialized(
  exportedObjects: GeometryStateType[],
  uuid?: string,
  name?: string
) {
  return JSON.stringify(
    exportedObjects.map((el) =>
      GeometryState.serializeExportedState(el, uuid, name)
    )
  )
}

export function convertMultiStateStringToExportedState(jsonString: string) {
  const jsonObj = JSON.parse(jsonString)

  let jsonObjArray: string[] = []

  if (jsonObj.version) {
    switch (jsonObj.version) {
      case '2.2':
        jsonObjArray = JSON.parse(jsonObj.value).map((el: any) =>
          JSON.stringify(el)
        )
    }
  } else if (Array.isArray(jsonObj))
    jsonObjArray = jsonObj.map((el) => JSON.stringify(el))
  else jsonObjArray = [JSON.stringify(jsonObj)]

  const exportedStates = jsonObjArray.map((el) =>
    GeometryState.convertStringToGeometryState(el)
  )

  return exportedStates
}

function processSchemaWithVersion(data: any, version: string) {}

export function getRelevantDataFromFetchedData(data: any) {
  if (data.version)
    return JSON.stringify({ value: data.value, version: data.version })
  if (data.value) return data.value
  else return JSON.stringify(data)
}

export function deserializeAndSetState(
  websiteState: WebsiteState,
  geometryState: GeometryState,
  jsonString: string
) {
  const exportedStates = convertMultiStateStringToExportedState(jsonString)
  const meshMakers = exportedStates.map(
    (state) => new MeshMaker(state, false, false)
  )
  const doneMeshes = meshMakers.map((meshMaker) =>
    meshMaker.getMesh(true, false)
  )

  websiteState.setExportedObjects(exportedStates)
  websiteState.setMeshObject(doneMeshes.map((doneMesh) => doneMesh[0]))
  websiteState.setBoundingBox(doneMeshes.map((doneMesh) => doneMesh[1]))
  geometryState.importState(exportedStates[0])
}

export function setStateFromFetched(
  websiteState: WebsiteState,
  geometryState: GeometryState,
  data: any
) {
  const relevantData = getRelevantDataFromFetchedData(data)
  deserializeAndSetState(websiteState, geometryState, relevantData)
}
