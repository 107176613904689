import { Vector2 } from 'three'
import { ModelType, modelTypes } from './constants'
import { CircleShape, IneShape, ShapeCurve } from './mesh-maker'
import { IneKnot, IneSpline, Spline } from './spline-classes'

export interface SurfaceModifierType {
  name: string
  amplitude?: number
  frequency?: number
}

export const getInitialShapes = (
  modelType: ModelType
): {
  startProfile: ShapeCurve
  endProfile: ShapeCurve
  intermediateShapes: ShapeCurve[]
} => {
  let knots = []
  let spline: IneSpline

  switch (modelType.id) {
    case modelTypes.boucle.id:
      knots = [
        new IneKnot(new Vector2(0.13, 0.34), 0, false, 1, true, false),
        new IneKnot(new Vector2(0.55, 0.34), 0, false, 0, true, false),
        new IneKnot(new Vector2(0.68, 0.27), 0, false, 1, true, false),
        new IneKnot(
          new Vector2(0.7118119220344388, 0.20817866941698554),
          0,
          false,
          0,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.7458255274766157, 0.13675009798841412),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.7526282485650511, 0.2489949959475978),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.6846010376806972, 0.36804261499521684),
          0,
          false,
          0,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.6369819900616497, 0.47688615241018284),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.7356214458439626, 0.7115800299612033),
          0,
          false,
          0,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.7356214458439626, 0.8705164072464924),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.6573901533269558, 0.7557977170360332),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.5417438948235545, 0.5439857950015944),
          0,
          false,
          0,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.48392076557185376, 0.47255722357302293),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.43630171795280615, 0.5337817133689413),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.43630171795280615, 0.7480674276546556),
          0,
          false,
          0,
          true,
          false
        ),

        new IneKnot(
          new Vector2(0.29344457509566324, 0.8705164072464924),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.11317246625212585, 0.7310606249335672),
          0,
          false,
          1,
          true,
          false
        ),
        new IneKnot(
          new Vector2(0.11317246625212585, 0.4113327337771046),
          0,
          false,
          0,
          true,
          false
        ),
      ]

      spline = new IneSpline(knots, modelTypes.boucle)
      return {
        startProfile: new IneShape(spline, 0, 0),
        endProfile: new IneShape(spline, 1, 0),
        intermediateShapes: [],
      }
      break

    case modelTypes.sketch.id:
      knots = [
        new IneKnot(new Vector2(0.3, 0.3), 0, false, 1, true, false),
        new IneKnot(new Vector2(0.7, 0.3), 0, false, 1, true, false),
        new IneKnot(new Vector2(0.7, 0.7), 0, false, 1, true, false),
        new IneKnot(new Vector2(0.3, 0.7), 0, false, 1, true, false),
      ]

      spline = new IneSpline(knots, modelTypes.boucle)
      return {
        startProfile: new IneShape(spline, 0, 0),
        endProfile: new IneShape(spline, 1, 0),
        intermediateShapes: [],
      }

      break

    default:
      return {
        startProfile: new CircleShape(10, 0, 0),
        endProfile: new CircleShape(10, 1, 0),
        intermediateShapes: [],
      }
      break
  }
}
