var t = require('transducers-js')
import { partitionAll } from './partitionall'
import { apply, first, dot, length, distance, is_true } from './utils2'

export function check_pairwise(f) {
  let ff = function (x) {
    if (x.length === 2) {
      apply(f, x)
      return first(x)
    }
    return first(x)
  }
  return t.comp(partitionAll(2, 1), t.map(ff))
}

//check constrains on 2 adjancent points
export function check_p_pn(opts, throws = true) {
  return function _check_p_pn(p1, p2) {
    let curvature = dot(p1.v, p2.v) / (length(p1.v) * length(p2.v))
    if (curvature < opts.path.max_curvature) {
      // angles greateer 45 °
      //console.log(
      //  "high curvature detected. increase discretation resolution.",
      //  curvature
      //);
      if (is_true(throws) && !(is_true(p1.required) || is_true(p2.required))) {
        console.log('hugh curvature detected')
      }
    }
    let dmax = opts.path.max_ddistance * 1.01
    let d_real = distance(p1.point, p2.point)
    if (d_real > dmax) {
      //console.log(
      //  "hugh deviation in point distances. might be caused by a bad defined topology."
      //);
      if (is_true(throws) && !(is_true(p1.required) || is_true(p2.required))) {
        //throw "hugh deviation in point distances detected";
        console.log('hugh deviation in point distances detected')
      }
    }
    if (p1.point[2] > p2.point[2]) {
      console.log(
        'none monoton increasing z detected.',
        p1.distance,
        p2.distance,
        p1.point,
        p2.point
      )
      if (is_true(throws)) {
        throw 'none monoton increasing z detected.'
      }
    }
    if (p1.point[2] >= p2.point[2] && p1.group == 'object') {
      console.log(
        'none strictly monoton increasing z for shell detected.',
        p1.distance,
        p2.distance,
        p1.point,
        p2.point
      )
      if (is_true(throws)) {
        throw 'none strictly monoton increasing z for shell detected.'
      }
    }
  }
}
