import {Vector3 } from 'three'

var t = require('transducers-js')
const { Map, List, Set } = require('immutable')

export function even(n) {
  return n % 2 == 0
}

export function inc(n) {
  return n + 1
}

export function dec(n) {
  return n - 1
}

export function nil(x) {
  if (typeof x === 'undefined') {
    return true
  }
  if (x === null) {
    return true
  }
  return false
}

export function is_false(x) {
  if (nil(x)) {
    return true
  }
  if (x === false) {
    return true
  }
  return false
}

export function complement(fn) {
  return function (...args) {
    if (is_false(fn(...args))) {
      return true
    }
    return false
  }
}

export const odd = complement(even)
export const is_true = complement(is_false)

export function first(arr) {
  return arr[0]
}

export function second(arr) {
  return arr[1]
}

export function last(arr) {
  return arr[arr.length - 1]
}

export function llast(arr) {
  return arr[arr.length - 2]
}

export function apply(fn, arr) {
  return fn(...arr)
}

export function min(arr) {
  return apply(Math.min, arr)
}

export function max(arr) {
  return apply(Math.max, arr)
}

export function sum(arr) {
  return arr.reduce((a, b) => a + b, 0)
}

export function vector(...args) {
  return List(args)
}

export function vec(args) {
  return List(args)
}

export function set(args) {
  return Set(args)
}

function assert_even_args(args) {
  if (odd(args.length)) {
    throw new Error('expect even number of args')
  }
}

export function hash_map(...args) {
  assert_even_args(args)
  const m = Map()
  const m2 = m.withMutations(function (m) {
    let mi = m
    for (var i = 0; i < args.length; i += 2) {
      mi = mi.set(args[i], args[i + 1])
    }
    return mi
  })
  return m2
}

export function get(m, k) {
  return m.get(k)
}

export function assoc(m, ...args) {
  assert_even_args(args)
  for (var i = 0; i < args.length; i += 2) {
    m = m.set(args[i], args[i + 1])
  }
  return m
}

export function update(m, k, f, ...args) {
  const v = get(m, k)
  return assoc(m, k, f(v, ...args))
}

export function* range(start, step, stop) {
  var value = start
  var check = (x) => true
  if (!nil(stop)) {
    check = (x) => x < stop
  }
  while (check(value)) {
    yield value
    value += step
  }
}

function pprint(data) {
  console.log(JSON.stringify(data, null, 2))
}

export function linear(v1, v2, t) {
  return v1 * (1 - t) + v2 * t
}

export function linear2(v1, v2, t1, t2, t) {
  let dt = t2 - t1
  let t_i = (t - t1) / dt
  return v1 * (1 - t_i) + v2 * t_i
}

export function dot(p1, p2) {
  let [x1, y1, z1] = p1
  let [x2, y2, z2] = p2
  return x1 * x2 + y1 * y2 + z1 * z2
}

export function cross(v1, v2) {
  const x = v1[1] * v2[2] - v1[2] * v2[1]
  const y = v1[2] * v2[0] - v1[0] * v2[2]
  const z = v1[0] * v2[1] - v1[1] * v2[0]
  return [x, y, z]
}

export function length(p) {
  let [x, y, z] = p
  return Math.sqrt(x * x + y * y + z * z)
}

export function length2d(p) {
  let [x, y] = p
  return Math.sqrt(x * x + y * y)
}

export function add(p2, p1) {
  let [x1, y1, z1] = p1
  let [x2, y2, z2] = p2
  let dx = x2 + x1
  let dy = y2 + y1
  let dz = z2 + z1
  return [dx, dy, dz]
}

export function add2d(p2, p1) {
  let [x1, y1] = p1
  let [x2, y2] = p2
  let dx = x2 + x1
  let dy = y2 + y1
  return [dx, dy]
}

export function scale2d(p, f) {
  let [x, y] = p
  return [x * f, y * f]
}

export function sub(p2, p1) {
  let [x1, y1, z1] = p1
  let [x2, y2, z2] = p2
  let dx = x2 - x1
  let dy = y2 - y1
  let dz = z2 - z1
  return [dx, dy, dz]
}

export function sub2d(p2, p1) {
  let [x1, y1] = p1
  let [x2, y2] = p2
  let dx = x2 - x1
  let dy = y2 - y1
  return [dx, dy]
}

export function normalize(v) {
  var l = length(v)
  var x = v[0] / l
  var y = v[1] / l
  var z = v[2] / l
  return [x, y, z]
}

export function normalize2d(v) {
  var l = length2d(v)
  var x = v[0] / l
  var y = v[1] / l
  return [x, y]
}

export function normal(p1, p2, p3) {
  let d1 = sub(p2, p1)
  let d2 = sub(p3, p1)
  let n = cross(d1, d2)
  return normalize(n)
}

export function distance(p1, p2) {
  return length(sub(p2, p1))
}

export function distance2d(p1, p2) {
  return length2d(sub2d(p2, p1))
}
//test geometry

export function circle(radius) {
  return (t) => [
    radius * Math.sin(t * 2 * Math.PI),
    radius * Math.cos(t * 2 * Math.PI),
  ]
}

export function line(length) {
  return (t) => [1, t * length]
}

export function brime_line(height, r_scale) {
  return (t) => {
    if (t <= 0.5) {
      return [t * 2 * r_scale, 0]
    } else {
      return [r_scale, (t - 0.5) * 2 * height]
    }
  }
}

export function square(r) {
  return (ti) => {
    let t = ti + 0.125
    if (t <= 0.25) {
      return [linear2(-r, r, 0, 0.25, t), r]
    }
    if (t <= 0.5) {
      return [r, linear2(r, -r, 0.25, 0.5, t)]
    }
    if (t <= 0.75) {
      return [linear2(r, -r, 0.5, 0.75, t), -r]
    }
    if (t <= 1) {
      return [-r, linear2(-r, r, 0.75, 1, t)]
    }
    if (t <= 1.25) {
      return [linear2(-r, r, 1, 1.25, t), r]
    }
  }
}

export function socket(ri, ra, height, shape) {
  let ci = circle(ri)
  let ca = circle(ra)
  return (u, v) => {
    let [xa, ya] = ca(u)
    if (v <= 0.5) {
      let [xi, yi] = ci(u)
      let x = linear2(xi, xa, 0, 0.5, v)
      let y = linear2(yi, ya, 0, 0.5, v)
      return [x, y, 0]
    } else {
      let [xh, yh] = shape(u)
      let x = linear2(xa, xh, 0.5, 1, v)
      let y = linear2(ya, yh, 0.5, 1, v)
      let z = linear2(0, height, 0.5, 1, v)
      return [x, y, z]
    }
  }
}

export function surface(shape, extrude) {
  return (u, v) => {
    let [x, y] = shape(u)
    let [i, z] = extrude(v)
    return [x * i, y * i, z]
  }
}

export function cylinder(radius, height) {
  return surface(circle(radius), line(height))
}

export const attach_deltas = t.map((p) => {
  let [p1, p2] = p
  p2 = p1.toJS()
  p2.v = sub(p2.point, p1.get('point'))
  p2.distance = length(p2.v)
  p2.average_layerheight = linear(p1.get('layer_height'), p2.layer_height, 0.5)
  return Map(p2)
})

export function vector3(x = 0, y = 0, z = 0) {
  let v = new Vector3(x, y, z)
  return v
}

const Z_PRECISION = 0.0001 // When approximating z by v on a surface s(u,v), then z is found if dz is <= Z_PRECISION

export function find_u_for_z(s, z) {
  var z_found = 0
  var u = 0.5
  var delta = 0.25
  const [, , z_max] = s(0, 1)
  const [, , z_min] = s(0, 0)
  if (z >= z_max) {
    return 1
  }
  if (z <= z_min) {
    return 0
  }
  ;[, , z_found] = s(0, u)
  while (Math.abs(z - z_found) > Z_PRECISION) {
    if (z_found > z) {
      u = u - delta
    } else {
      u = u + delta
    }
    delta = delta / 2
    ;[, , z_found] = s(0, u)
  }
  return u
}

function zip(arrays) {
  return arrays[0].map(function (_, i) {
    return arrays.map(function (array) {
      return array[i]
    })
  })
}

export function clip(v, i1, i2) {
  return Math.max(Math.min(v, i2), i1)
}

export function intersection_circle_line(pr, r, line) {
  let [p1, p12] = line
  let [p1x, p1y] = p1
  let [p12x, p12y] = p12
  let [prx, pry] = pr
  let a = p12x
  let b = p1x - prx
  let c = p12y
  let d = p1y - pry
  let p = (2 * a * b + 2 * c * d) / (a * a + c * c)
  let q = (b * b + d * d - r * r) / (a * a + c * c)
  let mph = -p / 2
  let quad = Math.sqrt((p * p) / 4 - q)
  let t1 = mph - quad
  let t2 = mph + quad
  return [add2d(scale2d(p12, t1), p1), add2d(scale2d(p12, t2), p1)]
}

export function dot2d(p1, p2) {
  let [p1x, p1y] = p1
  let [p2x, p2y] = p2
  return p1x * p2x + p1y * p2y
}
