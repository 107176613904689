var Shape = require('@doodle3d/clipper-js')
import {
  sub2d,
  length2d,
  normalize2d,
  intersection_circle_line,
  last,
  first,
  distance2d,
  nil,
} from './utils2'

const scale = 10000

function to_clip(path, closed = true) {
  var newArr = path.map(function (subpath, index) {
    return subpath.map(function (val, i) {
      return { X: val[0] * scale, Y: val[1] * scale }
    })
  })
  return new Shape.default(newArr, closed, false, true)
}

function from_clip(shape) {
  let paths = shape.paths

  var results = paths.map(function (subpath, i1) {
    return subpath.map(function (val, i2) {
      return [val.X / scale, val.Y / scale]
    })
  })
  return results
}

export function nearest_i_and_d(curve, point) {
  var nearest_i = null
  var distance = Number.MAX_VALUE
  curve.forEach((p, i) => {
    let new_distance = distance2d(p, point)
    if (new_distance <= distance) {
      nearest_i = i
      distance = new_distance
    }
  })
  return [nearest_i, distance]
}
function shift_curves(curves, last_curve, v) {
  //  shift curves, so that the begin of next curve is closest to the end of last_curve
  // curve in positive last_curve direction
  let value = Math.abs(v)
  if (curves.length === 1) {
    let curve = curves[0]
    if (last_curve.length < 2 || curve.length < 2) {
      return curves
    } else {
      let point = last(last_curve)
      let [nearest_i, distance] = nearest_i_and_d(curve, point)

      if (!nil(nearest_i)) {
        let new_curve = curve.slice(nearest_i)
        new_curve = new_curve.concat(curve.slice(0, nearest_i))
        let pf = first(new_curve)
        let pl = last(new_curve)
        let new_distance = length2d(sub2d(pf, pl))
        if (new_distance > value) {
          let dir = normalize2d(sub2d(pf, pl))
          let [p1, _] = intersection_circle_line(pf, value, [pl, dir])
          new_curve.push(p1)
        }
        return [new_curve]
      } else {
        return curves
      }
    }
  } else {
    return curves
  }
}

function counter(n) {
  var c = n
  return () => {
    if (c > 0) {
      c -= 1
      return true
    } else {
      return false
    }
  }
}

function _offset(results, path, value, n) {
  var shape = to_clip(path).clean()
  var terminator = () => {
    return true
  }
  if (!nil(n)) {
    terminator = counter(n)
  }
  while (terminator()) {
    shape = shape.clean().offset(value * scale, {
      jointType: 'jtSquare',
      endType: 'etClosedPolygon',
    })
    if (shape.paths.length == 0) {
      return results
    }
    if (shape.paths.length == 1) {
      let last_curve = last(results)
      let shifted = shift_curves(from_clip(shape), last_curve, value)
      results = results.concat(shifted)
    } else {
      let last_curve = last(results)
      let point = last(last_curve)
      let clipped = from_clip(shape)
      let ds_is = clipped.map((curve, index) => {
        let [i, d] = nearest_i_and_d(curve, point)
        return [index, d]
      })
      ds_is.sort((p1, p2) => {
        let [i1, d1] = p1
        let [i2, d2] = p2
        if (d1 < d2) {
          return -1
        }
        if (d2 < d1) {
          return 1
        }
        return 0
      })
      let indices = ds_is.map((x) => {
        return x[0]
      })
      for (var i = 0; i < indices.length; i++) {
        let index = indices[i]
        let shifted = shift_curves([clipped[index]], last_curve, value)
        results = results.concat(_offset(shifted, shifted, value))
      }
      return results
    }
  }
  return results
}
export function offset(path, value, n) {
  var shape = to_clip(path).clean()
  var results = [].concat(from_clip(shape))
  return _offset(results, path, value, n)
}

export function offset_n(path, value, n) {
  var shape = to_clip(path).clean()
  var results = [].concat(from_clip(shape))
  for (var i = 0; i < n; i++) {
    shape = shape.clean().offset(value * scale, {
      jointType: 'jtSquare',
      endType: 'etClosedPolygon',
    })
    if (shape.paths.length == 0) {
      return results
    } else {
      let last_curve = last(results)
      let shifted = shift_curves(from_clip(shape), last_curve, value)
      results = results.concat(shifted)
    }
  }
  return results
}

export function close_path(path) {
  var result = []
  result = result.concat(path)
  result.push(path[0])
  return result
}

export function clip(outer, inner) {
  let outer_shape = to_clip(outer, false).clean()
  var inner_shape = to_clip(inner).clean()
  let clipped = outer_shape.difference(inner_shape)
  return from_clip(clipped)
}

export function intersect(curve1, curve2) {
  let c1 = to_clip(curve1)
  let c2 = to_clip(curve2)
  let r = c1.intersect(c2)
  return r.paths.length > 0
}

export function contains(curve1, curve2) {
  let c1 = to_clip([curve1])
  let c2 = to_clip([curve2])
  let r = c1.union(c2)
  return Math.abs(r.totalArea()) === Math.abs(c1.totalArea())
}
