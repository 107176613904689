export function scanline(polygons, opts) {
  var curves = []
  let edges = []
  let intersections = []
  let minY = Number.MAX_VALUE
  let maxY = Number.MIN_VALUE

  // Iterate over the polygons to find their edges and the minimum/maximum Y coordinates
  for (let i = 0; i < polygons.length; i++) {
    let polygon = polygons[i]

    for (let j = 0; j < polygon.length; j++) {
      let vertex1 = polygon[j]
      let vertex2 = polygon[(j + 1) % polygon.length]

      // Add the edge to the list of edges
      edges.push([vertex1, vertex2])

      // Update the minimum/maximum Y coordinates
      minY = Math.min(minY, vertex1[1], vertex2[1])
      maxY = Math.max(maxY, vertex1[1], vertex2[1])
    }
  }
  for (
    let y = minY + opts.nozzle_size / 2;
    y <= maxY - opts.nozzle_size / 2;
    y += opts.nozzle_size
  ) {
    // Find the edges that intersect this scan line
    for (let i = 0; i < edges.length; i++) {
      let edge = edges[i]
      let vertex1 = edge[0]
      let vertex2 = edge[1]

      if (
        (vertex1[1] <= y && vertex2[1] > y) ||
        (vertex2[1] <= y && vertex1[1] > y)
      ) {
        // This edge intersects the scan line, so calculate the X coordinate of the intersection
        let slope = (vertex2[0] - vertex1[0]) / (vertex2[1] - vertex1[1])
        let x = (y - vertex1[1]) * slope + vertex1[0]

        // Add the intersection point to the list of intersections
        intersections.push(x)
      }
    }

    // Sort the list of intersection points in ascending order
    intersections.sort((a, b) => a - b)

    // Iterate over each pair of adjacent intersection points and draw a line between them
    for (let i = 0; i < intersections.length - 1; i += 2) {
      let x1 = intersections[i]
      let x2 = intersections[i + 1]
      curves.push([
        [x1, y],
        [x2, y],
      ])
    }

    // Clear the list of intersection points for the next scan line
    intersections = []
  }
  return curves
}

export function scanline90(polygons, opts) {
  var curves = []
  let edges = []
  let intersections = []
  let minX = Number.MAX_VALUE
  let maxX = Number.MIN_VALUE

  // Iterate over the polygons to find their edges and the minimum/maximum Y coordinates
  for (let i = 0; i < polygons.length; i++) {
    let polygon = polygons[i]

    for (let j = 0; j < polygon.length; j++) {
      let vertex1 = polygon[j]
      let vertex2 = polygon[(j + 1) % polygon.length]

      // Add the edge to the list of edges
      edges.push([vertex1, vertex2])

      // Update the minimum/maximum Y coordinates
      minX = Math.min(minX, vertex1[0], vertex2[0])
      maxX = Math.max(maxX, vertex1[0], vertex2[0])
    }
  }
  for (
    let x = minX + opts.nozzle_size / 2;
    x <= maxX - opts.nozzle_size / 2;
    x += opts.nozzle_size
  ) {
    // Find the edges that intersect this scan line
    for (let i = 0; i < edges.length; i++) {
      let edge = edges[i]
      let vertex1 = edge[0]
      let vertex2 = edge[1]

      if (
        (vertex1[0] <= x && vertex2[0] > x) ||
        (vertex2[0] <= x && vertex1[0] > x)
      ) {
        // This edge intersects the scan line, so calculate the X coordinate of the intersection
        let slope = (vertex2[1] - vertex1[1]) / (vertex2[0] - vertex1[0])
        let y = (x - vertex1[0]) * slope + vertex1[1]

        // Add the intersection point to the list of intersections
        intersections.push(y)
      }
    }

    // Sort the list of intersection points in ascending order
    intersections.sort((a, b) => a - b)

    // Iterate over each pair of adjacent intersection points and draw a line between them
    for (let i = 0; i < intersections.length - 1; i += 2) {
      let y1 = intersections[i]
      let y2 = intersections[i + 1]
      curves.push([
        [x, y1],
        [x, y2],
      ])
    }

    // Clear the list of intersection points for the next scan line
    intersections = []
  }
  return curves
}
